import { getData, postData } from './Api';
import { SuccessResponse } from './types/ResponseTypes';
import { HandwrittenLetterMetrics, CommercialOutreachMetrics, ReactivateLostClientsMetrics } from '../types/MetricTypes';
import { LeadNurtureSettings, BusinessEvent } from '../types/BusinessTypes';
import { EmailForwardingSettings, ReactivateLostClientConditions } from '../types/UserTypes';
import { BusinessLostClientAnalytics } from '../types/AnalyticTypes';

const GetBusinessUrls = {
  GetBusinessMetricsUrl: '/api/business/metrics/',
  GetBusinessEventsUrl: '/api/business/events/',
  GetBusinessAnalyticsUrl: '/api/business/analytics/',
}

const PostBusinessUrls = {
  UpdateBusinessRevenueEstimatorUrl: '/api/business/revenue/estimate/update',
  UpdateBusinessLeadNurtureSettingsUrl: '/api/business/lead/automatic-nurture/update',
  UpdateBusinessLostClientReactivationSettingsUrl: '/api/business/lost-clients/reactivation-conditions/update',
  UpdateBusinessLostClientEmailForwardingSettingsUrl: '/api/business/lost-clients/email-forwarding/update',
};

const {
  UpdateBusinessRevenueEstimatorUrl,
  UpdateBusinessLeadNurtureSettingsUrl,
  UpdateBusinessLostClientReactivationSettingsUrl,
  UpdateBusinessLostClientEmailForwardingSettingsUrl,
} = PostBusinessUrls;
const {
  GetBusinessMetricsUrl,
  GetBusinessEventsUrl,
  GetBusinessAnalyticsUrl,
} = GetBusinessUrls;

// Request interfaces

/**
 * Interface for request involved in updating a business's revenue estimator fields.
 */
interface UpdateBusinessRevenueEstimatorRequest {
  businessId: string,
  clientToLeadsProportion: number,
  revenuePerClient: number,
}

/**
 * Interface for request involved in updating a business's lead automatic nurture settings.
 */
interface UpdateBusinessLeadNurtureSettingsRequest {
  businessId: string,
  leadNurtureSettings: LeadNurtureSettings,
}

/**
 * Interface for request involved in updating a business's lost client reactivation conditions settings.
 */
interface UpdateBusinessLostClientSettingsRequest {
  businessId: string,
  reactivateLostClientConditions: ReactivateLostClientConditions[],
}

/**
 * Interface for request involved in updating a business's lost client reactivation conditions settings.
 */
interface UpdateBusinessEmailForwardingSettingsRequest {
  businessId: string,
  emailForwardingSettings: EmailForwardingSettings,
}

// Response interfaces

/**
 * Interface for business metrics fetch response. 
 * Expects handwritten letter, reactivate lost clients, and commercial outreach metrics.
 */
interface FetchBusinessAnalyticsResponse extends SuccessResponse {
  reactivateClientAnalytics: BusinessLostClientAnalytics
}

/**
 * Interface for business metrics fetch response. 
 * Expects handwritten letter, reactivate lost clients, and commercial outreach metrics.
 */
interface FetchBusinessMetricsResponse extends SuccessResponse {
  handwrittenLetterMetrics: HandwrittenLetterMetrics,
  reactivateLostClientsMetrics: ReactivateLostClientsMetrics,
  commercialOutreachMetrics: CommercialOutreachMetrics,
}


/**
 * Interface for business events fetch response.
 */
interface FetchBusinessEventsResponse extends SuccessResponse {
  businessEvents: BusinessEvent[],
}

// GET requests

/**
 * Get business metrics data from backend. Adds the business id as a request parameter.
 */
export async function getBusinessMetrics(businessId: string): Promise<FetchBusinessMetricsResponse> {
  return getData<FetchBusinessMetricsResponse>(`${GetBusinessMetricsUrl}${businessId}`);
}

/**
 * Get business events data from backend. Adds the business id as a request parameter.
 */
export async function getBusinessEvents(businessId: string): Promise<FetchBusinessEventsResponse> {
  return getData<FetchBusinessEventsResponse>(`${GetBusinessEventsUrl}${businessId}`);
}

/**
 * Get business metrics data from backend. Adds the business id as a request parameter.
 */
export async function getBusinessAnalytics(businessId: string): Promise<FetchBusinessAnalyticsResponse> {
  return getData<FetchBusinessAnalyticsResponse>(`${GetBusinessAnalyticsUrl}${businessId}`);
}

// POST Requests

/**
 * Posts an update to business's lead automatic nurture settings.
 * These settings are used to automatically nurture leads based on their source.
 */
export async function postUpdateLeadNurtureSettings(
  businessId: string,
  leadNurtureSettings: LeadNurtureSettings
): Promise<SuccessResponse> {
  return postData<UpdateBusinessLeadNurtureSettingsRequest, SuccessResponse>(
    UpdateBusinessLeadNurtureSettingsUrl, { businessId, leadNurtureSettings }
  );
}
/**
 * Posts an update to business's revenue estimator fields:
 * @param businessId: The business id to update.
 * @param clientToLeadsProportion: The proportion of clients that are generated from leads.
 * @param revenuePerClient: The revenue generated per client.
 */
export async function postUpdateBusinessRevenueEstimator(
  businessId: string,
  clientToLeadsProportion: number,
  revenuePerClient: number
): Promise<SuccessResponse> {
  return postData<UpdateBusinessRevenueEstimatorRequest, SuccessResponse>(
    UpdateBusinessRevenueEstimatorUrl,
    { businessId, clientToLeadsProportion, revenuePerClient }
  );
}

/**
 * Posts an update to business's reactivate lost client condition field:
 * @param businessId: The business id to update.
 * @param reactivateLostClientConditions: The list of conditions at which a client becomes a lost client.
 */
export async function postUpdateBusinessLostClientCondition(
  businessId: string,
  reactivateLostClientConditions: ReactivateLostClientConditions[],
): Promise<SuccessResponse> {
  return postData<UpdateBusinessLostClientSettingsRequest, SuccessResponse>(
    UpdateBusinessLostClientReactivationSettingsUrl,
    { businessId, reactivateLostClientConditions }
  );
}

/**
 * Posts an update to business's reactivate lost client condition field:
 * @param businessId: The business id to update.
 * @param emailForwardingSettings: The list of conditions at which a client becomes a lost client.
 */
export async function postUpdateBusinessEmailForwardingSettings(
  businessId: string,
  emailForwardingSettings: EmailForwardingSettings,
): Promise<SuccessResponse> {
  return postData<UpdateBusinessEmailForwardingSettingsRequest, SuccessResponse>(
    UpdateBusinessLostClientEmailForwardingSettingsUrl,
    { businessId, emailForwardingSettings }
  );
}