import { ReactElement } from 'react';

import ConfirmationModalContent from '../../../common/modals/ConfirmationModalContent';
import Modal from '../../../common/modals/Modal';

interface DeleteLeadConfirmationModalProps {
  cancel: () => void,
  confirm: () => void,
};

function DeleteLeadConfirmationModal({ cancel, confirm } : Readonly<DeleteLeadConfirmationModalProps>): ReactElement<DeleteLeadConfirmationModalProps> {
  return (
    <Modal>
      <ConfirmationModalContent
        title={'Delete this lead?'}
        subtitle={'This will remove this lead from the business.'}
        cancel={cancel}
        confirm={confirm}
        confirmText={'Confirm'}
        cancelText={'Cancel'}
      />
    </Modal>
  );
}

export default DeleteLeadConfirmationModal;

