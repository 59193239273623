import './RecoverClientsFilter.scss';

import React, { useState } from 'react';

// TODO: Refactor this out
// TODO: Replace this component with the generic component
import { RecoverClientsFilterType, RECOVER_CLIENTS_FILTER_ATTRIBUTES_MAP } from '../../common/multi-select-rows/filters/FilterConstants';

import AddRecoverClientsFilterModal from '../../engagements/modals/add-filter/AddRecoverClientsFilterModal';

import { EnrichedRecoverClientMessage } from '../reactivate-clients/RecoverClientsConstants';

import OutlineButton from '../../common/buttons/OutlineButton';
import LightPurpleTag from '../../common/tags/LightPurpleTag';

interface RecoverClientsFilterProps {
  recoverClientMessages: EnrichedRecoverClientMessage[] | null,
  filteredRecoverClientMessages: EnrichedRecoverClientMessage[] | null,
  resetPage: () => void,
  filters: RecoverClientsFilterType[],
  setFilters: React.Dispatch<React.SetStateAction<RecoverClientsFilterType[]>>,
};

function RecoverClientsFilter({ recoverClientMessages, filteredRecoverClientMessages, resetPage, filters, setFilters } : RecoverClientsFilterProps) {
  const resetFilters = () => setFilters([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const filteredAddressCount = recoverClientMessages && filteredRecoverClientMessages ? `(${filteredRecoverClientMessages.length}/${recoverClientMessages.length})` : '';
  const selectedFilters = filters.length > 0;

  // Not sure how to get around this issue of attribute potentially being null.
  // If I remove the null check I get this error: Type 'null' cannot be used as an index type
  const filterTags = filters
    .map(({ attribute }, index) => {
      if (attribute) {
        return <LightPurpleTag key={`${attribute}-${index}`} content={RECOVER_CLIENTS_FILTER_ATTRIBUTES_MAP[attribute].displayText} />;
      }
      return null;
    })

  const confirmFilters = (filters: RecoverClientsFilterType[]) => {
    const populatedFilters = filters.filter((filter: RecoverClientsFilterType) => filter.attribute);
    setFilters(populatedFilters);
    resetPage();
  };

  const filterText = `Filters${filters.length > 0 ? ` (${filters.length})` : ''}`;
  return (
    <section className={'address-filters'}>
      <OutlineButton additionalClassNames={['filter-button']} content={filterText} onClick={() => setFilterOpen(true)} />
      {filterTags}
      {selectedFilters && <span>{filteredAddressCount}</span>}
      {filterOpen && <AddRecoverClientsFilterModal parentFilters={filters} close={() => setFilterOpen(false)} confirm={confirmFilters} resetFilters={resetFilters} />}
    </section>
  );
}

export default RecoverClientsFilter;