import { useEffect, useState, Dispatch, SetStateAction } from 'react';

import { Address } from '../HandwrittenLettersConstants';
import { getAddressesForBusiness } from '../../../../apis/LettersApi';

interface BusinessAddressesData {
  addresses: Address[],
  setAddresses: (addresses: Address[]) => void,
  loading: boolean,
  cursor: number,
  setCursor: Dispatch<SetStateAction<number>>,
};

export function useBusinessAddresses(businessId: string | null): BusinessAddressesData {
  const [cursor, setCursor] = useState(0);
  const [addresses, setAddresses] = useState<Address[]| null>(null);
  
  useEffect(() => {
    if (businessId) {
      setCursor(0);
      setAddresses(null);
      getAddressesForBusiness(businessId).then(({ addresses }) => setAddresses(addresses));
    }
  }, [businessId]);

  return {
    addresses: addresses || [],
    setAddresses,
    loading: addresses === null,
    cursor,
    setCursor,
  };
}