import { ReactElement } from 'react'

import ProgressBar from './ProgressBar'

interface BlackProgressBarProps {
  completionNumber: number,
  total: number,
  height: number,
}

function BlackProgressBar({ completionNumber, total, height } : Readonly<BlackProgressBarProps>): ReactElement<BlackProgressBarProps> {
  return <ProgressBar completionNumber={completionNumber} total={total} height={height} backgroundColor={'#F1F5F9'} borderColor={'transparent'} barColor={'#0F172A'} />;
}

export default BlackProgressBar;
