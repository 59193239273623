import Modal from '../../../common/modals/Modal';
import ConfirmationModalContent from '../../../common/modals/ConfirmationModalContent';

interface SendMessageModalProps {
  cancel: () => void,
  confirm: () => void,
};

function SendMessageModal({ cancel, confirm } : Readonly<SendMessageModalProps>) {
  return (
    <Modal additionalClassNames={['send-message-modal']}>
      <ConfirmationModalContent
        title={'Send Message'}
        subtitle={'Please confirm you would like to send out your messages.'}
        cancel={cancel}
        confirm={confirm}
      />
    </Modal>
  )
};

export default SendMessageModal;