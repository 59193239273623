import './QuickSelectModal.scss';

import { OptionType } from '../../dropdowns/DropdownConstants';

interface QuickSelectModalProps {
  quickSelectRows: (amount: number) => void
};

const QuickSelectOptions: OptionType<number>[] = [
  { displayText: '25', value: 25 },
  { displayText: '50', value: 50 },
  { displayText: '100', value: 100 },
]

function QuickSelectModal({ quickSelectRows } : Readonly<QuickSelectModalProps>) {
  const quickSelectOptions = QuickSelectOptions.map(({ displayText, value }) => {
    return (
      <button
        onClick={() => quickSelectRows(value)}
        className={'quick-select-option-button'}
        key={`quick-select-${value}`}
      >
        {displayText}
      </button>
    );
  });
  return (
    <section className={'quick-select-modal'}>
      <section className={'quick-select-container'}>
        {quickSelectOptions}
      </section>
    </section>
  );
}

export default QuickSelectModal;