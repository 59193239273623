import './RemovableItem.scss';

import { ReactElement, ReactNode } from 'react';

import TrashIconButton from '../../common/buttons/composite/TrashIconButton';

interface RemovableItemProps {
  additionalClassNames?: string[],
  children: ReactNode,
  removeItem: () => void,
}

function EmailList({ removeItem, children, additionalClassNames = [] } : RemovableItemProps): ReactElement<RemovableItemProps> {
  return (
    <section className={`removable-item ${additionalClassNames.join(' ')}`}>
      {children}
      <TrashIconButton onClick={removeItem}/>
    </section>
  );
}

export default EmailList;