import './Table.scss';

import { ReactNode, ReactElement } from 'react';

import CHECKED_CHECKBOX_ICON from './assets/checked-checkbox.svg';
import UNCHECKED_CHECKBOX_ICON from './assets/unchecked-checkbox.svg';
import IconButton from '../buttons/IconButton';

interface TableCell {
  key: string,
  value: string | number | ReactNode,
};

export interface TableRow {
  cells: TableCell[],
  selected?: boolean,
  isSelectorDisabled?: boolean,
  selectRow?: () => void,
};

export interface TableHeaderProps {
  displayText: string | ReactNode,
  key: string,
  className?: string,
};

interface TableProps {
  additionalClassNames?: string[],
  headers: TableHeaderProps[],
  rows: TableRow[],
  emptyTableDisplay?: string | ReactNode,
  tableName?: string,
  maxRows?: number | null,
  cursor?: number,
  freezeFirstColumn?: boolean,
  hasCounter?: boolean,
  hasSelector?: boolean,
  selectAllRows?: () => void,
};

// Counter column width in pixels
const CounterColumnWidth = 44;

// Selector column width in pixels
const SelectorColumnWidth = 47;

function Table({
  additionalClassNames = [],
  headers = [],
  rows = [],
  emptyTableDisplay = null,
  tableName = '',
  maxRows = null,
  cursor = 0,
  freezeFirstColumn = true,
  selectAllRows = () => {},
  hasCounter = true,
  hasSelector = false,
} : Readonly<TableProps>): ReactElement<TableProps> | null {

  if (rows.length === 0 && emptyTableDisplay === null) {
    return null;
  }
  const classNames = ['table', ...additionalClassNames].join(' ');

  // Handles the offset left pixels of the first column when a counter + selector column is present
  let firstColumnOffset = 0;
  let selectorOffset = 0;
  const counterHeader = hasCounter ? <th key={`${tableName}-header-counter`} style={{ left: 0 }} className={['hidden', freezeFirstColumn ? 'sticky' : ''].join(' ')}></th> : null;

  // Adds the size of the counter column to the left offset
  if (hasCounter) {
    selectorOffset += CounterColumnWidth;
    firstColumnOffset += CounterColumnWidth;
  }
  if (hasSelector) {
    firstColumnOffset += SelectorColumnWidth;
  }

  // Select all functionality
  const hasSelected = rows.filter(({ selected }) => selected).length > 0;
  const headerCheckBoxIcon = hasSelected ? CHECKED_CHECKBOX_ICON : UNCHECKED_CHECKBOX_ICON;
  const selectorHeader = hasSelector ? (
    <th key={`${tableName}-header-selector`} style={{ left: selectorOffset }} className={['one-button-cell', freezeFirstColumn ? 'sticky' : ''].join(' ')}>
      <IconButton
        onClick={selectAllRows}
        icon={headerCheckBoxIcon}
        additionalClassNames={['clickable']}
      />
    </th>
  ) : null;

  const tableDisplay = rows
    .slice(cursor, maxRows ? cursor + maxRows : rows.length)
    .map(({ cells, selected, isSelectorDisabled, selectRow }, rowIndex) => {
      const cellNodes = cells.map(({ key, value }, colIndex) => {
        const counterColumnClassNames = `counter-column ${freezeFirstColumn ? 'sticky' : ''}`;
        const counterNode = hasCounter && colIndex === 0
          ? <td key={`${tableName}-row-counter-${rowIndex}`} className={counterColumnClassNames}>{cursor + rowIndex +  1}</td>
          : null;
        const checkbox = selected ? CHECKED_CHECKBOX_ICON : UNCHECKED_CHECKBOX_ICON;
        const selectorNode = hasSelector && colIndex === 0
          ? (
            <td key={`${tableName}-row-selector-${rowIndex}`} style={{ left: selectorOffset }} className={['one-button-cell', freezeFirstColumn ? 'sticky' : ''].join(' ')}>
              {
                !isSelectorDisabled && (
                  <IconButton
                    onClick={selectRow}
                    icon={checkbox}
                    additionalClassNames={['clickable']}
                  />
                )
              }
            </td>
          )
          : null;
        let firstCellStyle = {};
        if (colIndex === 0) {
          firstCellStyle = { left: firstColumnOffset };
        }
        const cellClassName = freezeFirstColumn && colIndex === 0 ? 'sticky primary-index' : '';
        return (
          <>
            {counterNode}
            {selectorNode}
            <td key={`${tableName}-row-${key}-${colIndex}`} className={cellClassName} style={firstCellStyle}>{value}</td>
          </>
        );
      });
      return (
        <tr key={`${tableName}-row-${rowIndex}`}>
          {cellNodes}
        </tr>
      );
    });
  return (
    <table className={classNames}>
      <thead className={'table-header'}>
        <tr>
          {counterHeader}
          {selectorHeader}
          {headers.map(({ key, displayText, className = '' }, index) => {
            const cellClassName = freezeFirstColumn && index === 0 ? 'sticky primary-index' : '';
            let style = {};
            if (index === 0) {
              style = { left: firstColumnOffset };
            }
            return <th key={key} className={[className, cellClassName].join(' ')} style={style}>{displayText}</th>;
          })}
        </tr>
      </thead>
      <tbody className={'table-body'}>
        { rows.length === 0 ? emptyTableDisplay : tableDisplay }
      </tbody>
    </table>
  )
};

export default Table;