import './WorkRexPage.scss';

import { Outlet } from 'react-router';

import NavigationSidebar from '../navigation/sidebar/NavigationSidebar';
import { CASE_STUDIES_NAVIGATION_ITEMS } from '../navigation/sidebar/NavigationSidebarConstants';

function WorkRexPageWrapper() {
  return (
    <main className={'work-rex-page'}>
      <NavigationSidebar sidebarNavigationItems={CASE_STUDIES_NAVIGATION_ITEMS} />
      <section className={'sidebar-buffer-container'}>
        <Outlet />
      </section>
    </main>
  )
}

export default WorkRexPageWrapper;