import { FilterType, FilterAttributeMapType } from '../../common/filters/types/FilterTypes';
import { OptionType } from '../../common/dropdowns/DropdownConstants';

import { DashboardMetric } from '../dashboard/DashboardConstants';
import { Message } from '../reactivate-clients/MessageConstants';
import { LeadMetrics } from '../../../types/MetricTypes';
import { LeadSource } from '../../../types/LeadTypes';
import { LeadNurtureSettings, NurtureStep } from '../../../types/BusinessTypes';

import TOTAL_LEADS_ICON from './assets/total-leads.svg';
import LAST_THIRTY_DAYS_ICON from './assets/last-thirty-days.svg';


const { STRING } = FilterType;

export const LeadMetricItems: DashboardMetric[] = [
  {
    label: 'Total leads',
    icon: TOTAL_LEADS_ICON,
    tooltipText: null,
    fieldName: 'leads',
  },
  {
    label: 'Last 30 days',
    icon: LAST_THIRTY_DAYS_ICON,
    tooltipText: null,
    fieldName: 'leadsInLastThirtyDays',
  },
];

export const EmptyLeadMetrics: LeadMetrics = {
  leads: {
    value: '0',
    lastUpdateDate: null,
  },
  leadsInLastThirtyDays: {
    value: '0',
    lastUpdateDate: null,
  }
}

export const LeadSourceOptions: OptionType<LeadSource>[] = [
  { displayText: LeadSource.LivesNearby, value: LeadSource.LivesNearby },
  { displayText: LeadSource.WorksNearby, value: LeadSource.WorksNearby },
];

export const LeadsFilterOptions: OptionType<string>[] = [
  { displayText: 'First Name', value: 'firstName' },
  { displayText: 'Last Name', value: 'lastName' },
  { displayText: 'Mobile Number', value: 'mobileNumber' },
  { displayText: 'Email Address', value: 'emailAddress' },
];

export const LeadsFilterAttributeMap: FilterAttributeMapType = {
  firstName: {
    key: 'firstName',
    displayText: 'First Name',
    type: STRING
  },
  lastName: {
    key: 'lastName',
    displayText: 'Last Name',
    type: STRING
  },
  mobileNumber: {
    key: 'mobileNumber',
    displayText: 'Mobile',
    type: STRING
  },
  emailAddress: {
    key: 'emailAddress',
    displayText: 'Email',
    type: STRING
  },
}

export interface LeadMessage extends Message {
  _id: string,
}


export const EmptyNurtureStep: NurtureStep = {
  daysToWait: 0,
  message: '',
};

export const DefaultLeadNurtureSettings: LeadNurtureSettings = {
  nurtureFlows: {
    [LeadSource.LivesNearby]: {
      stopNurtureIfResponse: true,
      steps: [],
    },
    [LeadSource.WorksNearby]: {
      stopNurtureIfResponse: true,
      steps: [],
    },
  }
}

export enum NurtureStatus {
  NotStarted = 'Not started',
  InProgress = 'In progress',
  Completed = 'Completed',
}

export interface NurtureResult {
  nurtureStatus: NurtureStatus,
  nurtureButtonText: string,
  sentMessagesCount: number,
  totalMessagesCount: number
}