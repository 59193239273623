import { useEffect, useState, Dispatch, SetStateAction } from 'react';

import { Lead } from '../../../../types/LeadTypes';
import { getBusinessLeads } from '../../../../apis/LeadApi';

interface BusinessLeadsData {
  leads: Lead[],
  setLeads: (leads: Lead[]) => void,
  loading: boolean,
  cursor: number,
  setCursor: Dispatch<SetStateAction<number>>,
};

export function useBusinessLeads(businessId: string | null): BusinessLeadsData {
  const [cursor, setCursor] = useState(0);
  const [leads, setLeads] = useState<Lead[]| null>(null);
  
  useEffect(() => {
    if (businessId) {
      setCursor(0);
      setLeads(null);
      getBusinessLeads(businessId).then(({ leads }) => setLeads(leads));
    }
  }, [businessId]);

  return {
    leads: leads || [],
    setLeads,
    loading: leads === null,
    cursor,
    setCursor
  };
}