import { Lead, LeadNurtureFlow } from '../../../../types/LeadTypes'
import { LeadMetrics } from '../../../../types/MetricTypes';
import { EmptyLeadMetrics } from '../LeadsConstants';
import { NurtureResult, NurtureStatus } from '../LeadsConstants';

// TODO: Replace with time constants
const THIRTY_DAYS_AGO_IN_MS = 30 * 24 * 60 * 60 * 1000;

export function computeLeadMetrics(leads: Lead[] | null): LeadMetrics {
  if (leads === null) {
    return EmptyLeadMetrics;
  }
  const mostRecentLeadDate = leads.length > 0 ? Math.max(...leads.map(({ date, creationDate  }) => date || creationDate)) : null;
  const leadsInLastThirtyDays = leads.filter(({ date, creationDate }) => {
    const leadDate = date || creationDate;
    return leadDate > Date.now() - THIRTY_DAYS_AGO_IN_MS;
  });
  const mostRecentLeadDateInLastThirtyDays = leadsInLastThirtyDays.length > 0 ? Math.max(...leadsInLastThirtyDays.map(({ date, creationDate  }) => date || creationDate)) : null;
  return {
    leads: {
      value: `${leads.length}`,
      lastUpdateDate: mostRecentLeadDate,
    },
    leadsInLastThirtyDays: {
      value: `${leadsInLastThirtyDays.length}`,
      lastUpdateDate: mostRecentLeadDateInLastThirtyDays,
    }
  };
}

export const getNurtureStatus = (nurtureFlow: LeadNurtureFlow | null | undefined): NurtureResult => {
  if (!nurtureFlow) {
    return { nurtureStatus: NurtureStatus.NotStarted, sentMessagesCount: 0, totalMessagesCount: 0, nurtureButtonText: 'Begin nurture' };
  }
  const { messages, stopNurtureIfResponse, stoppedDate, repliedDate } = nurtureFlow;
  const sentMessages = messages.filter(({ sentDate }) => sentDate !== null);
  const totalMessagesCount = messages.length;
  const sentMessagesCount = sentMessages.length;
  const isStopped = stoppedDate !== null;
  const isComplete = (stopNurtureIfResponse && repliedDate !== null) || sentMessagesCount === totalMessagesCount;
  if (isStopped || isComplete) {
    return { nurtureStatus: NurtureStatus.Completed, sentMessagesCount, totalMessagesCount, nurtureButtonText: 'View nurture' };
  }
  return { nurtureStatus: NurtureStatus.InProgress, sentMessagesCount, totalMessagesCount, nurtureButtonText: 'Cancel nurture' };
}