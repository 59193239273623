import { Location } from '../../../../types/GeoTypes';

// Proximity level constants
const LEVEL_FOUR = 4;
const LEVEL_THREE = 3;
const LEVEL_TWO = 2;
const LEVEL_ONE = 1;
const LEVEL_ZERO = 0;
const LEVEL_FOUR_DISTANCE_IN_KM = 2;
const LEVEL_THREE_DISTANCE_IN_KM = 5;
const LEVEL_TWO_DISTANCE_IN_KM = 10;
const LEVEL_ONE_DISTANCE_IN_KM = 20;

interface Proximity {
  level: number,
  tooltipText: string,
}

/** Returns haversine distance between two points in kilometres. */
export function getHaversineDistanceInKM(pointA: Location, pointB: Location): number {
  const radius = 6371; // radius of the earth in km     

  //convert latitude and longitude to radians
  const deltaLatitude = (pointB.latitude - pointA.latitude) * Math.PI / 180;
  const deltaLongitude = (pointB.longitude - pointA.longitude) * Math.PI / 180;

  const halfChordLength = Math.cos(
      pointA.latitude * Math.PI / 180) * Math.cos(pointB.latitude * Math.PI / 180) 
      * Math.sin(deltaLongitude/2) * Math.sin(deltaLongitude/2)
      + Math.sin(deltaLatitude/2) * Math.sin(deltaLatitude/2);

  const angularDistance = 2 * Math.atan2(Math.sqrt(halfChordLength), Math.sqrt(1 - halfChordLength));

  return (radius * angularDistance) / 1000;
}

export const getProximityText = (businessLocation: Location | null | undefined, addressLocation: Location | null | undefined): Proximity | null => {
  if (!businessLocation || !addressLocation) {
    return null;
  }
  const distance = getHaversineDistanceInKM(businessLocation, addressLocation);

  if (distance <= LEVEL_FOUR_DISTANCE_IN_KM) {
    return { level: LEVEL_FOUR, tooltipText: 'Within 2 km or 1.2 miles' };
  }
  if (distance <= LEVEL_THREE_DISTANCE_IN_KM) {
    return { level: LEVEL_THREE, tooltipText: 'Within 5 km or 3.1 miles' };
  }
  if (distance <= LEVEL_TWO_DISTANCE_IN_KM) {
    return { level: LEVEL_TWO, tooltipText: 'Within 10 km or 6.2 miles' };
  }
  if (distance <= LEVEL_ONE_DISTANCE_IN_KM) {
    return { level: LEVEL_ONE, tooltipText: 'Within 20 km or 12.4 miles' };
  }
  return { level: LEVEL_ZERO, tooltipText: 'More than 20 km or 12.4 miles' };
}