import './RecoverClientsFilterRow.scss';

import React from 'react';

import DateInput from '../../inputs/DateInput';
import Dropdown from '../../dropdowns/Dropdown';
import MultiSelectRow from '../MultiSelectRow';
import PrimaryInput from '../../inputs/PrimaryInput';
import {
  RecoverClientsFilterAttributeKey,
  RECOVER_CLIENTS_FILTER_ATTRIBUTES,
  CONDITION_OPTIONS_MAPPING,
  RECOVER_CLIENTS_FILTER_ATTRIBUTES_MAP,
  FILTER_CONDITIONS_MAP,
} from './FilterConstants';
import { ConditionKey } from '../MultiSelectRowConstants';
import { FilterType } from '../../filters/types/FilterTypes';

const { IsEmptyKey, IsNotEmptyKey } = ConditionKey;
const { DATE } = FilterType;

const EMPTY_CONDITIONS = [IsEmptyKey, IsNotEmptyKey];

interface RecoverClientsFilterRowProps {
  attribute: RecoverClientsFilterAttributeKey | null,
  setAttribute: (attribute: RecoverClientsFilterAttributeKey) => void,
  condition: ConditionKey | null,
  setCondition: (condition: ConditionKey) => void,
  input: string | number,
  setInput: (input: number | string) => void,
  deleteRow: () => void,
}

function RecoverClientsFilterRow({ attribute, setAttribute, condition, setCondition, input, setInput, deleteRow } : Readonly<RecoverClientsFilterRowProps>) {
  const showConditions = attribute !== null;
  const showInput = condition !== null && !EMPTY_CONDITIONS.includes(condition);

  // Need to figure out a better way to do this.
  const attributeType = showConditions ? RECOVER_CLIENTS_FILTER_ATTRIBUTES_MAP[attribute].type : '';

  const dateAttribute = attributeType === DATE;
  const dateInput = dateAttribute && input
    ? new Date(input)
    : null;

  let inputField = <PrimaryInput placeholder={'Add value'} text={`${input}`} setText={setInput} />;
  if (showConditions && showInput) {
    const conditionObj = FILTER_CONDITIONS_MAP[attributeType][condition];
    if (conditionObj && conditionObj.valueType === DATE) {
      inputField = <DateInput selectedDate={dateInput} setDate={setInput} />
    }
  }
  
  return (
    <MultiSelectRow deleteRow={deleteRow}>
      <Dropdown defaultDisplayText={'Select attribute'} options={RECOVER_CLIENTS_FILTER_ATTRIBUTES} selected={attribute} select={setAttribute} additionalClassNames={['reactivate-clients-attributes-dropdown']} />
      { showConditions && attributeType && <Dropdown defaultDisplayText={'Select condition'} options={CONDITION_OPTIONS_MAPPING[attributeType]} selected={condition} select={setCondition} additionalClassNames={['reactivate-clients-conditions-dropdown']} /> }
      { showInput && inputField }
    </MultiSelectRow>
  );
}

export default RecoverClientsFilterRow;