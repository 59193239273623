import './DashboardPage.scss';

import { ReactElement } from 'react';

import CaseStudyCardsContainer from '../common/CaseStudyCardsContainer';
import DashboardMetrics from '../common/DashboardMetrics';

function DashboardPage(): ReactElement {
  return (
    <article className={'dashboard-page'}>
      <section className={'page'}>
        <section className={'dashboard'}>
          <header className={'dashboard-header'}>
            <h2 className={'title'}>{'Dashboard'}</h2>
            <h3 className={'subtitle'}>{'Overview of your account'}</h3>
          </header>
          <section className={'dashboard-page-content'}>
            <DashboardMetrics />
            <CaseStudyCardsContainer />
          </section>
        </section>
      </section>
    </article>
  );
}

export default DashboardPage;