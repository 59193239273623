import { useEffect, useState } from 'react';

import { BusinessMetrics } from '../../../../types/MetricTypes';
import { getBusinessMetrics } from '../../../../apis/BusinessApi';

interface BusinessMetricsData {
  metrics: BusinessMetrics | null,
  setMetrics: (businessMetrics: BusinessMetrics) => void,
  loading: boolean,
};

export function useBusinessMetrics(businessId: string | null): BusinessMetricsData {
  const [metrics, setMetrics] = useState<BusinessMetrics | null>(null);

  useEffect(() => {
    if (businessId) {
      setMetrics(null);
      getBusinessMetrics(businessId)
        .then(({ handwrittenLetterMetrics, reactivateLostClientsMetrics, commercialOutreachMetrics}) => 
          setMetrics({ handwrittenLetterMetrics, reactivateLostClientsMetrics, commercialOutreachMetrics }));
    }
  }, [businessId]);
  
  return {
    metrics,
    setMetrics,
    loading: metrics === null,
  };
}