import { ReactElement } from 'react';

import { LeadNurtureFlow } from '../../../../types/LeadTypes';

import GreyTag from '../../../common/tags/GreyTag';
import GreenTag from '../../../common/tags/GreenTag';
import GreenProgressBar from '../../../common/bars/GreenProgressBar';

import { NurtureStatus } from '../LeadsConstants';
import { getNurtureStatus } from '../helpers/LeadHelpers';

interface NurtureStatusTagProps {
  nurtureFlow?: LeadNurtureFlow | null,
}

function NurtureStatusTag({ nurtureFlow } : Readonly<NurtureStatusTagProps>): ReactElement<NurtureStatusTagProps> {
  const { nurtureStatus, sentMessagesCount, totalMessagesCount } = getNurtureStatus(nurtureFlow);
  if (nurtureStatus === NurtureStatus.NotStarted) {
    return <GreyTag content={nurtureStatus} />;
  }
  else if (nurtureStatus === NurtureStatus.Completed) {
    return <GreenTag content={'Completed'} />;
  }
  return (
    <>
    <GreenProgressBar completionNumber={sentMessagesCount} total={totalMessagesCount} height={10} />
    <span className={'progress-text'}>{`${sentMessagesCount}/${totalMessagesCount} messages sent`}</span>
  </>
  );
}

export default NurtureStatusTag;