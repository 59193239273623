import './CreditDetails.scss';

import { ReactElement } from 'react';

import BlackProgressBar from '../../../common/bars/BlackProgressBar';
import LoadingRing from '../../../common/loading/LoadingRing';

interface CreditDetailsProps {
  usedCredits: number | null,
  totalCredits: number,
}

function CreditDetails({ usedCredits, totalCredits } : Readonly<CreditDetailsProps>): ReactElement<CreditDetailsProps> {
  const loading = usedCredits === null;
  const creditDetailsSummary = (
    <>
      <p className={'credit-details-summary'}>
        {'You have used '}
        <b>{usedCredits}</b>
        {' out of '}
        <b>{totalCredits}</b>
        {' credits.'}
      </p>
      <section className={'progress-bar-container'}>
        <BlackProgressBar completionNumber={usedCredits || 0} total={totalCredits} height={9} />
        <span className={'counter'}>{`${usedCredits}/${totalCredits}`}</span>
      </section>
    </>
  );

  return (
    <section className={`credit-details ${loading ? 'loading' : ''}`}>
      <h4 className={'credit-details-title'}>{'Available credits'}</h4>
      <section className={'credit-details-summary-container'}>
        { usedCredits === null ? <LoadingRing color={'#000000'} /> : creditDetailsSummary }
      </section>
    </section>
  );
}

export default CreditDetails;