import './ExportButton.scss';

import { MouseEvent } from 'react';

import LeftIconButtonContent from '../content/LeftIconButtonContent';
import SubtleButton from '../SubtleButton';

import SHARE_ICON from './assets/share-icon.svg';

interface ExportButtonProps {
  onClick?: (event: MouseEvent) => void,
  disabled?: boolean,
  loading?: boolean
}

function ExportButton({ onClick, disabled, loading } : Readonly<ExportButtonProps>) {
  return(
    <SubtleButton
      additionalClassNames={['export-button']}
      content={<LeftIconButtonContent icon={SHARE_ICON} content={'Export'} />}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
    />
  )

}

export default ExportButton;