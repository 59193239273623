import { useEvent, useFlatfile, usePlugin, Workbook } from "@flatfile/react";

import useAuth from '../../../../context/AuthContext';

import { RecipientFlatFileButtonProps, recipientWorkbook } from "../../../../utils/flatfile/FlatFileConstants";
import { recipientSubmitListener, recipientRecordFormattingListener } from "../../../../utils/flatfile/FlatFileHelpers";
import { recordHook } from "@flatfile/plugin-record-hook";
import OutlineButton from "../../../common/buttons/OutlineButton";

function RecipientFlatFileButton({ defaultDisplayText } : RecipientFlatFileButtonProps) {
  const { openPortal } = useFlatfile();
  const { selectedBusinessId } = useAuth();

  usePlugin(
    recordHook(
      'recipients',
      recipientRecordFormattingListener
    ),
    ['status']
  )

  // Event hook for submitting recipients
  useEvent('job:ready',
    { job: 'workbook:submitRecipients' },
    async (event) => {
      if (selectedBusinessId) {
        await recipientSubmitListener(event, selectedBusinessId);
      }
      // getRecipients()
      //   .then(response => {
      //     if (response) {
      //       console.log(response);
      //       const { recipients } = response;
      //       setRecipients(recipients);
      //     } else {
      //       setRecipients([]);
      //     }
      //   })
      //   .catch(err => {
      //     setRecipients([]);
      //     console.log(err);
      //   })
    }
  );

  return (
    <>
      <OutlineButton content={defaultDisplayText} onClick={openPortal} />
      <Workbook config={recipientWorkbook} />
    </>
  )
};

export default RecipientFlatFileButton;