import { useEffect, useState } from 'react';

import { getBusinessAnalytics } from '../../../../apis/BusinessApi';
import { BusinessLostClientAnalytics } from '../../../../types/AnalyticTypes';

interface BusinessAnalyticsData {
  analytics: BusinessLostClientAnalytics | null,
  setAnalytics: (businessAnalytics: BusinessLostClientAnalytics) => void,
  loading: boolean,
};

export function useBusinessAnalytics(businessId: string | null): BusinessAnalyticsData {
  const [analytics, setAnalytics] = useState<BusinessLostClientAnalytics | null>(null);

  useEffect(() => {
    if (businessId) {
      setAnalytics(null);
      getBusinessAnalytics(businessId)
        .then(({ reactivateClientAnalytics }) => 
          setAnalytics(reactivateClientAnalytics));
    }
  }, [businessId]);
  
  return {
    analytics,
    setAnalytics,
    loading: analytics === null,
  };
}