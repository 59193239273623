import './CommercialOutreachTableButtons.scss';

import { ReactElement, useState } from 'react';

import useAuth from '../../../../context/AuthContext';

import OutlineButton from '../../../common/buttons/OutlineButton';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import GhostButton from '../../../common/buttons/GhostButton';

import SHOW_ICON from '../../assets/show-completed-icon.svg';
import HIDE_ICON from '../../assets/hide-completed-icon.svg';


interface CommercialOutreachTableButtonsProps {
  sendButtonText: string,
  openConfirmModal: () => void,
  showSendAllButton: boolean,
  showCompleted: boolean,
  setShowCompleted: React.Dispatch<React.SetStateAction<boolean>>
};

function CommercialOutreachTableButtons({ sendButtonText, openConfirmModal, showSendAllButton, showCompleted, setShowCompleted } : Readonly<CommercialOutreachTableButtonsProps>): ReactElement {
  const { business } = useAuth();
  const programUrl = business?.programUrl;
  const messageUrl = business?.messageUrl;
  const toggleCompleted = () => setShowCompleted(!showCompleted);

  const viewMessage = (): void => {
    if (messageUrl) {
      window.open(messageUrl, "_blank");
    }
  };
  const viewProgram = (): void => {
    if (programUrl) {
      window.open(programUrl, "_blank");
    }
  };

  const showCompletedToggle = (
    <section className={'show-completed-toggle'}>
      <img className={'show-completed-icon'} src={showCompleted ? SHOW_ICON : HIDE_ICON} alt={'Show completed icon'} />
      <span className={'button-text'}>{showCompleted ? 'Showing completed' : 'Hiding completed'}</span>
    </section>
  );
  return (
    <section className={'table-buttons'}>
      { programUrl && <OutlineButton additionalClassNames={['view-offer-button']} content={'View program'} onClick={viewProgram} /> }
      { messageUrl && <OutlineButton additionalClassNames={['view-letter-button']} content={'View message'} onClick={viewMessage} /> }
      { showSendAllButton && <PrimaryButton additionalClassNames={['send-all-button']} content={sendButtonText} onClick={openConfirmModal} /> }
      <GhostButton additionalClassNames={['show-complete-button']} content={showCompletedToggle} onClick={toggleCompleted} />
    </section>
  )
}

export default CommercialOutreachTableButtons;