import { CountryCode, Location } from '../../../types/GeoTypes';
import { OptionType } from '../../common/dropdowns/DropdownConstants';
import { FilterableItem, FilterItemWithKey, FilterType } from '../../common/filters/types/FilterTypes';

const { STRING, NUMBER, DATE } = FilterType;

export enum LetterStatus {
  All = 'All',
  Available = 'Available',
  Requested = 'Requested',
  Complete = 'Complete',
};

export enum AddressType {
  Sale = 'Sale',
  Rent = 'Rent',
};

export interface Address extends FilterableItem {
  _id: string,
  address: string,
  searchValue: string,
  status: LetterStatus,
  propertyValue: string,
  addressType: AddressType,
  transactionDate: string,
  countryCode?: CountryCode,
  addressLocation?: Location | null,
  shouldSend: boolean,
};

export enum AddressFilterAttributeKey {
  AddressKey = 'address',
  SearchValueKey = 'searchValue',
  StatusKey = 'status',
  PropertyValueKey = 'propertyValue',
  AddressTypeKey = 'addressType',
  TransactionDateKey = 'transactionDate',
  SendKey = 'shouldSend',
}

export enum AddressFilterAttributeText {
  AddressText = 'Address',
  SearchValueText = 'Search value',
  StatusText = 'Status',
  PropertyValueText = 'Property Value',
  AddressTypeText = 'Type',
  TransactionDateText = 'Transaction Date',
  SendText = 'Send',
};

export type AddressFilterType = FilterItemWithKey<AddressFilterAttributeKey>;

export const AddressFilterAttributeMap = {
  address: {
    key: AddressFilterAttributeKey.AddressKey,
    displayText: AddressFilterAttributeText.AddressText,
    type: STRING
  },
  searchValue: {
    key: AddressFilterAttributeKey.SearchValueKey,
    displayText: AddressFilterAttributeText.SearchValueText,
    type: STRING
  },
  status: {
    key: AddressFilterAttributeKey.StatusKey,
    displayText: AddressFilterAttributeText.StatusText,
    type: STRING
  },
  propertyValue: {
    key: AddressFilterAttributeKey.PropertyValueKey,
    displayText: AddressFilterAttributeText.PropertyValueText,
    type: NUMBER
  },
  addressType: {
    key: AddressFilterAttributeKey.AddressTypeKey,
    displayText: AddressFilterAttributeText.AddressTypeText,
    type: STRING
  },
  transactionDate: {
    key: AddressFilterAttributeKey.TransactionDateKey,
    displayText: AddressFilterAttributeText.TransactionDateText,
    type: DATE
  },
  shouldSend: {
    key: AddressFilterAttributeKey.SendKey,
    displayText: AddressFilterAttributeText.SendText,
    type: STRING
  },
};

export const AddressFilterAttributes = Object.values(AddressFilterAttributeMap);
export const AddressFilterAttributeOptions: OptionType<AddressFilterAttributeKey>[] = AddressFilterAttributes.map(({ key, displayText }) => ({ value: key, displayText }));