import './AddFilterModal.scss';

import { useState } from 'react';

import { OptionType } from '../../dropdowns/DropdownConstants';

import Modal from '../../modals/Modal';
import OutlineButton from '../../buttons/OutlineButton';
import PrimaryButton from '../../buttons/PrimaryButton';
import PrimaryPlusButton from '../../buttons/composite/PrimaryPlusButton';

import { EmptyFilter } from '../FilterConstants';
import { ConditionKey, FilterAttributeMapType, FilterItem } from '../types/FilterTypes';

import FilterRow from './FilterRow';

interface AddFilterModalProps {
  parentFilters: FilterItem[],
  close: () => void,
  confirm: (filters: FilterItem[]) => void,
  resetFilters: () => void,
  filterAttributeMap: FilterAttributeMapType,
  filterOptions: OptionType<string>[],
}

function AddFilterModal({ parentFilters, close, confirm, resetFilters, filterAttributeMap, filterOptions } : Readonly<AddFilterModalProps>) {
  const [filters, setFilters] = useState<FilterItem[]>(parentFilters);
  const applyFilters = () => {
    confirm(filters);
    close();
  };
  const reset = () => {
    resetFilters();
    setFilters([]);
  }
  const addFilter = () => {
    const newFilters = [...filters, { ...EmptyFilter }];
    setFilters(newFilters);
  }
  const setFilter = (index: number, fieldName: string, value: string | number) => {
    const newFilters = [...filters];
    newFilters[index] = { ...newFilters[index], [fieldName]: value };
    setFilters(newFilters);
  }

  const deleteFilter = (index: number) => {
    const newFilters = filters.filter((_, i) => i !== index);
    setFilters(newFilters);
  }

  const filterList = filters.map(({ attribute, condition, input }, index) => {
    const setAttribute = (attribute: string) => setFilter(index, 'attribute', attribute);
    const setCondition = (condition: ConditionKey) => setFilter(index, 'condition', condition);
    const setInput = (input: string | number) => setFilter(index, 'input', input);
    const deleteRow = () => deleteFilter(index);
    return (
      <FilterRow
        key={`filter-${index}`}
        attribute={attribute}
        setAttribute={setAttribute}
        condition={condition}
        setCondition={setCondition}
        input={input}
        setInput={setInput}
        filterAttributeMap={filterAttributeMap}
        filterOptions={filterOptions}
        deleteRow={deleteRow}
      />
    );
  });
  return (
    <Modal additionalClassNames={['add-filter-modal']}>
      <header className={'add-filter-modal-header'}>
        <h4 className={'add-filter-title'}>
          {'Add filters'}
        </h4>
        <h5 className={'add-filter-subtitle'}>
          {'You can add multiple filters'}
        </h5>
      </header>
      <section className={'add-filter-content'}>
        { filters.length > 0 && (
          <section className={'filters'}>
            {filterList}
          </section>
        )}
        <PrimaryPlusButton content={'New filter'} onClick={addFilter} />
      </section>
      <section className={'add-filter-buttons'}>
        <OutlineButton content={'Reset'} onClick={reset} />
        <PrimaryButton content={'Apply'} onClick={applyFilters} />
      </section>
    </Modal>
  );
}

export default AddFilterModal;