import './MessagePreview.scss';

import React, { useState, useRef, useEffect } from 'react';

import PaginationBar from '../../../common/tables/PaginationBar';

import { Message, MessageStatus } from '../MessageConstants';

import MessagePreviewHeader from './MessagePreviewHeader';
import MessagePreviewFooter from './MessagePreviewFooter';
import SmsPreview from './SmsPreview';
import FineTuneModal from '../modals/FineTuneModal';
import { useNavigate } from 'react-router';

interface MessagePreviewProps {
  messages: Message[],
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
  totalMessages: number,
}

const MAX_ROWS_PER_PAGE = 1;

function MessagePreview({ messages, setMessages, totalMessages } : MessagePreviewProps) {
  const navigate = useNavigate();
  const navigateToManagePage = () => navigate('/reactivate-clients/manage');
  const [cursor, setCursor] = useState(0);
  const { recipientName, message, sendTo, messageStatus } = messages[cursor];
  const contentRef = useRef(message);
  const [tuningModalOpen, setTuningModalOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const saveMessage = () => {
    const newMessages = [...messages];
    newMessages[cursor].message = contentRef.current;
    setMessages(newMessages);
  }
  const openTuningModal = () => setTuningModalOpen(true);
  const closeTuningModal = () => setTuningModalOpen(false);
  const fineTunePrompt = () => {
    saveMessage();
    setEditing(false);
    openTuningModal();
  }
  const saveFineTunedMessages = () => {
    closeTuningModal();
    navigateToManagePage();
  }
  // const saveFineTunedMessages = (fineTunedMessages: Message[]) => {
  //   setMessages(fineTunedMessages)
  //   closeTuningModal();
  //   navigateToManagePage();
  // }
  const target = `${sendTo} (Mobile)`;
  const messageNumber = cursor + 1;

  const saveAndSetCursor = (updatedCursor: number) => {
    saveMessage();
    setEditing(false);
    setCursor(updatedCursor);
  }

  // Updates the html message as the message is stored in a ref rather than React state
  // This is a consequence of using React-content editable
  useEffect(() => {
    const { message } = messages[cursor];
    contentRef.current = message;
  }, [messages, cursor]);

  const editingDisabled = messageStatus === MessageStatus.Delivered;
  return (
    <section className={'preview-message-container'}>
      {tuningModalOpen && <FineTuneModal messages={messages} close={closeTuningModal} confirm={saveFineTunedMessages} />}
      <section className={'preview-message'}>
        <MessagePreviewHeader name={recipientName} target={target} messageNumber={messageNumber} totalMessages={totalMessages} />
        <section className={'message-container'}>
          <SmsPreview content={message} editing={editing} contentRef={contentRef} />
        </section>
        <MessagePreviewFooter
          disabled={editingDisabled}
          editing={editing}
          setEditing={setEditing}
          openTuningModal={fineTunePrompt}
          save={saveMessage}
        />
      </section>
      <PaginationBar cursor={cursor} setCursor={saveAndSetCursor} maxRowsPerPage={MAX_ROWS_PER_PAGE} totalRows={totalMessages} />
    </section>
  );
};

export default MessagePreview;