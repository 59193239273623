import { Flatfile } from '@flatfile/api';
import { Status } from '../../components/people/PeopleConstants';
import { RecipientRow } from '../../components/common/multi-select-rows/filters/FilterHelpers';
import { Address, AddressType, LetterStatus } from '../../components/case-studies/living-nearby/HandwrittenLettersConstants';

export const flatfileConfig = {
  exitText: '',
  exitTitle: 'Close Window',
  exitPrimaryButtonText: 'Yes',
  exitSecondaryButtonText: 'No',
  displayAsModal: true,
};

export interface RecipientFlatFileComponentProps {
  setRecipients: React.Dispatch<React.SetStateAction<RecipientRow[]>>
};

export interface RecipientFlatFileButtonProps {
  defaultDisplayText: string,
}

export interface InteractionFlatFileComponentProps {
  defaultDisplayText: string,
  onSubmit: () => void
}

export interface AddressFlatFileComponentProps {
  setAddresses: (addresses: Address[]) => void
};

export const recipientSheet: Flatfile.SheetConfig = {
  name: 'Recipients',
  slug: 'recipients',
  fields: [
    {
      key: 'clientId',
      type: 'string',
      label: 'Client ID',
    },
    {
      key: "firstName",
      label: "First Name",
      type: "string",
      constraints: [
        { type: 'required' }
      ]
    },
    {
      key: "lastName",
      label: "Last Name",
      type: "string",
      constraints: [
        { type: 'required' }
      ]
    },
    {
      key: "fullName",
      label: "Full Name",
      type: "string",
    },
    {
      key: 'mobileNumber',
      type: 'string',
      label: 'Mobile Number',
    },
    {
      key: 'emailAddress',
      type: 'string',
      label: 'Email Address',
    },
    {
      key: "deliverability",
      label: "Deliverability",
      type: "enum",
      constraints: [
        { type: 'required' }
      ],
      config: {
        options: [
          {
            value: "SUBSCRIBED",
            label: "Subscribed",
            color: "#ff0000",
            icon: "fa fa-ban",
          },
          {
            value: "OPTED_OUT",
            label: "Opted out",
            color: "#ff0000",
            icon: "fa fa-ban",
          },
        ]
      }
    },
    {
      key: "acceptsSMSMarketing",
      label: "Accepts SMS Marketing",
      type: "enum",
      config: {
        options: [
          {
            value: "TRUE",
            label: "True",
            color: "#ff0000",
            icon: "fa fa-ban",
          },
          {
            value: "FALSE",
            label: "False",
            color: "#ff0000",
            icon: "fa fa-ban",
          },
        ]
      }
    },
    {
      key: "acceptsEmailMarketing",
      label: "Accepts Email Marketing",
      type: "enum",
      config: {
        options: [
          {
            value: "TRUE",
            label: "True",
            color: "#ff0000",
            icon: "fa fa-ban",
          },
          {
            value: "FALSE",
            label: "False",
            color: "#ff0000",
            icon: "fa fa-ban",
          },
        ]
      }
    },
    {
      key: 'addedDate',
      type: 'date',
      label: 'Added Date',
    },
    {
      key: 'dateOfBirth',
      type: 'date',
      label: 'Date of Birth',
    },
    {
      key: 'source',
      type: 'string',
      label: 'Source',
    },
    {
      key: 'address',
      type: 'string',
      label: 'Address',
    },
    {
      key: "status",
      label: "Status",
      type: "enum",
      config: {
        options: [
          {
            value: Status.ACTIVE,
            label: "Active",
            color: "#ff0000",
            icon: "fa fa-ban",
          },
          {
            value: Status.NOT_ACTIVE,
            label: "Not Active",
            color: "#ff0000",
            icon: "fa fa-ban",
          },
          {
            value: Status.DELETED,
            label: "Deleted",
            color: "#ff0000",
            icon: "fa fa-ban",
          },
        ]
      }
    },
  ]
}

export const interactionSheet: Flatfile.SheetConfig = {
  name: 'Interactions',
  slug: 'interactions',
  fields: [
    {
      key: "firstName",
      label: "First Name",
      type: "string",
    },
    {
      key: "lastName",
      label: "Last Name",
      type: "string",
    },
    {
      key: "fullName",
      label: "Full Name",
      type: "string",
    },
    {
      key: "clientId",
      label: "Client ID",
      type: "string",
    },
    {
      key: "emailAddress",
      label: "Email Address",
      type: "string"
    },
    {
      key: "mobileNumber",
      label: "Mobile Number",
      type: "number"
    },
    {
      key: 'referenceId',
      type: 'string',
      label: 'Ref #',
    },
    {
      key: "interactionType",
      label: "Interaction Type",
      type: "enum",
      constraints: [
        { type: 'required' }
      ],
      config: {
        options: [
          {
            value: "TRANSACTION",
            label: "Transaction",
            color: "#ff0000",
            icon: "fa fa-ban",
          },
          {
            value: "APPOINTMENT",
            label: "Appointment",
            color: "#ff0000",
            icon: "fa fa-ban",
          },
          {
            value: "MESSAGE",
            label: "Message",
            color: "#ff0000",
            icon: "fa fa-ban",
          }
        ]
      }
    },
    {
      key: 'interactionDetails',
      type: 'string',
      label: 'Interaction Details',
    },
    {
      key: 'scheduledDate',
      type: 'date',
      label: 'Scheduled Date',
      constraints: [
        { type: 'required' }
      ],
    },
    {
      key: "transactionCurrency",
      label: "Transaction Currency",
      type: "enum",
      constraints: [
        { type: 'required' }
      ],
      config: {
        options: [
          {
            value: "AUD",
            label: "AUD",
            color: "#ff0000",
            icon: "fa fa-ban",
          },
          {
            value: "NZD",
            label: "NZD",
            color: "#ff0000",
            icon: "fa fa-ban",
          },
          {
            value: "USD",
            label: "USD",
            color: "#ff0000",
            icon: "fa fa-ban",
          },
        ]
      }
    },
    {
      key: 'transactionValue',
      type: 'number',
      label: 'Transaction Value',
    },
    {
      key: "recipientId",
      label: "Recipient Id",
      type: "string"
    },
    {
      key: 'done',
      label: 'Done',
      type: 'boolean',
    }
  ]
}

export const addressSheet: Flatfile.SheetConfig = {
  name: 'Addresses',
  slug: 'addresses',
  fields: [
    {
      key: "address",
      label: "Address",
      type: "string",
    },
    {
      key: "searchValue",
      label: "Search value",
      type: "string",
    },
    {
      key: "status",
      label: "Status",
      type: "enum",
      readonly: true,
      constraints: [
        { type: 'required' },
      ],
      config: {
        options: [
          {
            value: LetterStatus.Requested,
            label: LetterStatus.Requested,
            color: "#ff0000",
            icon: "fa fa-ban",
          },
          {
            value: LetterStatus.Available,
            label: LetterStatus.Available,
            color: "#ff0000",
            icon: "fa fa-ban",
          },
          {
            value: LetterStatus.Complete,
            label: LetterStatus.Complete,
            color: "#ff0000",
            icon: "fa fa-ban",
          }
        ]
      }
    },
    {
      key: 'propertyValue',
      label: 'Property value',
      type: 'string',
    },
    {
      key: "addressType",
      label: "Type",
      type: "enum",
      constraints: [
        { type: 'required' }
      ],
      config: {
        options: [
          {
            value: AddressType.Sale,
            label: AddressType.Sale,
            color: "#ff0000",
            icon: "fa fa-ban",
          },
          {
            value: AddressType.Rent,
            label: AddressType.Rent,
            color: "#ff0000",
            icon: "fa fa-ban",
          },
        ]
      }
    },
    {
      key: 'transactionDate',
      label: 'Transaction date',
      type: 'date',
    },
    {
      key: "shouldSend",
      label: "Send",
      type: "boolean",
      readonly: true,
    },
  ]
}

export const recipientWorkbook: Flatfile.CreateWorkbookConfig = {
  name: "Recipient Data",
  labels: ["pinned"],
  namespace: "portal",
  sheets: [recipientSheet],
  actions: [
    {
      operation: "submitRecipients",
      mode: "foreground",
      label: "Submit Recipients",
      description: "Submit data to database",
      primary: true,
      constraints: [{ type: 'hasAllValid' }, { type: 'hasData' }],
      tooltip: 'Click to run action'
    },
  ],
};

export const interactionWorkbook: Flatfile.CreateWorkbookConfig = {
  name: "Interaction Data",
  labels: ["pinned"],
  namespace: "portal",
  sheets: [interactionSheet],
  actions: [
    {
      operation: 'syncInteractions',
      mode: 'foreground',
      label: 'Sync Interactions',
      description: 'Sync data to database',
      primary: true,
      constraints: [{ type: 'hasAllValid' }, { type: 'hasData' }],
      tooltip: 'Click to run action',
    },
    {
      operation: 'submitInteractions',
      mode: 'foreground',
      label: 'Submit Interactions',
      description: 'Submit data to database',
      primary: true,
      constraints: [{ type: 'hasAllValid' }, { type: 'hasData' }],
      tooltip: 'Click to run action',
    }
  ]
};

export const addressWorkbook: Flatfile.CreateWorkbookConfig = {
  name: "Address Data",
  labels: ["pinned"],
  namespace: "portal",
  sheets: [addressSheet],
  actions: [
    {
      operation: "submitAddresses",
      mode: "foreground",
      label: "Submit Addresses",
      description: "Submit data to database",
      primary: true,
      constraints: [{ type: 'hasAllValid' }, { type: 'hasData' }],
      tooltip: 'Click to run action'
    },
  ],
};