import './QuickSelectButton.scss';

import { useRef, useState } from 'react';

import { useOutsideClick } from '../../../../hooks/ClickHooks';

import DottedButton from '../DottedButton';
import QuickSelectModal from './QuickSelectModal';

import RIGHT_ARROW from './assets/right-arrow.svg';

interface QuickSelectButtonProps {
  quickSelectRows: (amount: number) => void
}

function QuickSelectButton({ quickSelectRows } : Readonly<QuickSelectButtonProps>) {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const closeModal = () => setShowModal(false);
  const quickSelect = (amount: number) => {
    quickSelectRows(amount);
    closeModal();
  }
  const content = (
    <section className={'quick-select-text'}>
      {'Quick select'}
      <img className={'quick-select-arrow-icon'} src={RIGHT_ARROW} alt={''}  />
    </section>
  );

  const buttonRef = useRef(null);
  useOutsideClick(buttonRef, closeModal);
  return (
    <section ref={buttonRef} className={'quick-select-button'}>
      <DottedButton content={content} onClick={toggleModal} />
      {showModal && <QuickSelectModal quickSelectRows={quickSelect} />}
    </section>
  )
}

export default QuickSelectButton;