import { useEffect, useState } from 'react';

import useAuth from '../../../../context/AuthContext';

import { DEFAULT_EMAIL_FORWARDING_SETTINGS, EmailForwardingSettings, ReactivateLostClientConditions } from '../../../../types/UserTypes';

interface BusinessLinkedInUsersData {
  emailForwardingSettings: EmailForwardingSettings,
  reactivationConditions: ReactivateLostClientConditions[],
  setEmailForwardingSettings: React.Dispatch<React.SetStateAction<EmailForwardingSettings | null>>,
  setReactivationConditions: React.Dispatch<React.SetStateAction<ReactivateLostClientConditions[] | null>>,
  loading: boolean,
};

export function useBusinessReactivateSettings(businessId: string | null): BusinessLinkedInUsersData {
  const [emailForwardingSettings, setEmailForwardingSettings] = useState<EmailForwardingSettings | null>(null);
  const [reactivationConditions, setReactivationConditions] = useState<ReactivateLostClientConditions[] | null>(null);
  const { business } = useAuth();
  
  useEffect(() => {
    if (businessId) {
      setEmailForwardingSettings(null);
      setReactivationConditions(null);
      setEmailForwardingSettings(business ? business.emailForwardingSettings : DEFAULT_EMAIL_FORWARDING_SETTINGS);
      setReactivationConditions(business ? business.reactivateLostClientConditions : []);
    }
  }, [businessId]);

  return {
    emailForwardingSettings: emailForwardingSettings || DEFAULT_EMAIL_FORWARDING_SETTINGS,
    reactivationConditions: reactivationConditions || [],
    setEmailForwardingSettings,
    setReactivationConditions,
    loading: emailForwardingSettings === null || reactivationConditions === null,
  };
}
