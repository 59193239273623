import { Location } from "../../../types/GeoTypes";
import { OptionType } from "../../common/dropdowns/DropdownConstants";
import { FilterableItem, FilterItemWithKey, FilterType } from "../../common/filters/types/FilterTypes";

const { STRING } = FilterType;

export enum CommercialOutreachStatus {
  All = 'All',
  Available = 'Available',
  'In progress' = 'In progress',
  Completed = 'Completed',
};

export interface LinkedInUser extends FilterableItem {
  _id: string,
  fullName: string,
  companyName: string,
  addressLocation?: Location | null,
  status: CommercialOutreachStatus,
  linkedInUrl: string,
  emailAddress: string,
};

export enum LinkedInUserFilterAttributeKey {
  FullNameKey = 'fullName',
  CompanyNameKey = 'companyName',
  StatusKey = 'status',
  EmailAddressKey = 'emailAddress',
}

export enum LinkedInUserFilterAttributeText {
  FullNameText = 'Full name',
  CompanyNameText = 'Company',
  StatusText = 'Status',
  EmailAddressText = 'Email',
};

export type LinkedInUserFilterType = FilterItemWithKey<LinkedInUserFilterAttributeKey>;

export const LinkedInUserFilterAttributeMap = {
  fullName: {
    key: LinkedInUserFilterAttributeKey.FullNameKey,
    displayText: LinkedInUserFilterAttributeText.FullNameText,
    type: STRING
  },
  companyName: {
    key: LinkedInUserFilterAttributeKey.CompanyNameKey,
    displayText: LinkedInUserFilterAttributeText.CompanyNameText,
    type: STRING
  },
  status: {
    key: LinkedInUserFilterAttributeKey.StatusKey,
    displayText: LinkedInUserFilterAttributeText.StatusText,
    type: STRING
  },
  emailAddress: {
    key: LinkedInUserFilterAttributeKey.EmailAddressKey,
    displayText: LinkedInUserFilterAttributeText.EmailAddressText,
    type: STRING
  },
};

export const LinkedInUserFilterAttributes = Object.values(LinkedInUserFilterAttributeMap);
export const LinkedInUserFilterAttributeOptions: OptionType<LinkedInUserFilterAttributeKey>[] = LinkedInUserFilterAttributes.map(({ key, displayText }) => ({ value: key, displayText }));