import '../pages/ViewLeadsPage.scss';

import { ReactElement } from 'react';

import useAuth from '../../../../context/AuthContext';

import LoadingRing from '../../../common/loading/LoadingRing';

import { useBusinessLeads } from '../hooks/LeadsHooks';
import { computeLeadMetrics } from '../helpers/LeadHelpers';
import { LeadMetricItems } from '../LeadsConstants';
import { DefaultLeadNurtureSettings } from '../LeadsConstants';

import DashboardMetricList from '../../dashboard/common/DashboardMetricList';
import LeadsTable from '../tables/LeadsTable';

function ViewLeadsPage(): ReactElement {
  const { selectedBusinessId, business } = useAuth();
  const nurtureSettings = business?.leadNurtureSettings || DefaultLeadNurtureSettings;
  const { leads, loading, setLeads, cursor, setCursor } = useBusinessLeads(selectedBusinessId);
  const leadMetrics = computeLeadMetrics(leads);
  const leadMetricList = (
    <section className={'leads-metrics-container'}>
      <DashboardMetricList metricItems={LeadMetricItems} metrics={leadMetrics} keyId={'leads'} />
    </section>
  );
  return (
    <article className={'view-leads-page'}>
      <section className={'page'}>
        <section className={'dashboard'}>
          <header className={'dashboard-header'}>
            <h2 className={'title'}>{'View leads'}</h2>
            <h3 className={'subtitle'}>{'Search and view leads from all your sources'}</h3>
          </header>
          { loading ? <LoadingRing color={'#000000'} /> : leadMetricList }
        </section>
        <section className={'leads'}>
          { loading ? null : (
              <LeadsTable
                leads={leads}
                setLeads={setLeads}
                nurtureSettings={nurtureSettings}
                cursor={cursor}
                setCursor={setCursor}
              /> 
          )}
        </section>
      </section>
    </article>
  );
}

export default ViewLeadsPage;