import './RoundedLetterTag.scss';

import { TagProps } from './TagConstants';

import Tag from './Tag';

function RoundedLetterTag({ content, additionalClassNames = [] }: Readonly<TagProps>) {
  return <Tag content={content} additionalClassNames={['rounded-letter', ...additionalClassNames]} />;
};

export default RoundedLetterTag;