import './MessagePreviewHeader.scss';

interface MessagePreviewHeaderProps {
  name: string,
  target: string,
  messageNumber: number,
  totalMessages: number,
};

function MessagePreviewHeader({ name, target, messageNumber, totalMessages } : Readonly<MessagePreviewHeaderProps>) {
  return (
    <header className={'preview-header'}>
      <section className={'labels'}>
        <span className={'preview-header-label'}>
          {'Send to'}
        </span>
        <span className={'preview-name'}>{name}</span>
        <span className={'preview-target'}>{target}</span>
      </section>
      <span className={'preview-number'}>{`Showing (${messageNumber}/${totalMessages})`}</span>
    </header>
  );
}

export default MessagePreviewHeader;