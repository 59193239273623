import { getData } from './Api';

import { User } from '../types/UserTypes';
import { SuccessResponse } from './types/ResponseTypes';

const GetUserUrls = {
  GetUserUrl: '/api/user',
};

const { GetUserUrl } = GetUserUrls;

// Request interfaces

/**
 * Request for getting the user from the server.
 */
interface UserRequest extends SuccessResponse {
  user: User | null,
}

// Get requests

/**
 * Retrieves the user from the server.
 */
export async function getUser(): Promise<UserRequest> {
  return getData(GetUserUrl);
}