import { useEvent, useFlatfile, usePlugin, Workbook } from '@flatfile/react';
import { FlatfileEvent } from '@flatfile/listener';
import { recordHook } from '@flatfile/plugin-record-hook';

import useAuth from '../../../context/AuthContext';
import { getAddressesForBusiness } from '../../../apis/LettersApi';

// TODO: Refactor out to flatfile components
import { AddressFlatFileComponentProps, addressWorkbook } from '../../../utils/flatfile/FlatFileConstants';
import { addressSubmitListener, addressRecordFormattingListener } from '../../../utils/flatfile/FlatFileHelpers';

import PrimaryButton from '../../common/buttons/PrimaryButton';

import UPLOAD_ICON from '../assets/upload-icon.svg';

function AddressFlatFileButton({ setAddresses } : AddressFlatFileComponentProps) {
  const { openPortal } = useFlatfile();
  const { selectedBusinessId } = useAuth();
  const uploadButtonContent = (
    <section className={'button-content'}>
      <img className={'upload-icon'} src={UPLOAD_ICON} alt={'Upload icon'} />
      <span className={'button-text'}>{'Request addresses'}</span>
    </section>
  );

  usePlugin(recordHook('addresses', addressRecordFormattingListener), ['status', 'shouldSend']);
  
  const submitAddressesCallback = async (event: FlatfileEvent) => {
    if (!selectedBusinessId) {
      return;
    }
    await addressSubmitListener(event, selectedBusinessId);
    getAddressesForBusiness(selectedBusinessId)
      .then(res => setAddresses(res.addresses))
      .catch(err => console.log(err));
  }

  // Event hook for submitting recipients
  useEvent('job:ready', { job: 'workbook:submitAddresses' }, submitAddressesCallback);
  return (
    <>
      <PrimaryButton content={uploadButtonContent} onClick={openPortal} />
      <Workbook config={addressWorkbook} />
    </>
  )
};

export default AddressFlatFileButton;