import './CommercialOutreachEmptyPage.scss';

import { ReactElement } from 'react';

import OutlinePlusButton from '../../../common/buttons/composite/OutlinePlusButton';

function CommercialOutreachEmptyPage(): ReactElement {
  return (
    <section className={'empty-commercial-outreach'}>
      <h3 className={'subtitle'}>{'No people found yet, check back later or begin a new search'}</h3>
      <OutlinePlusButton content={'Request outreach'} />
    </section>
  )
}

export default CommercialOutreachEmptyPage;