import './EmailList.scss';

import { ReactElement } from 'react';

import RemovableItem from '../../common/rows/RemovableItem';

interface EmailListProps {
  emails: string[],
  removeEmail: (index: number) => void,
}

function EmailList({ emails, removeEmail } : EmailListProps): ReactElement<EmailListProps> {
  const emailList = emails.map((email: string, index: number) => {
    const toText = index === 0 ? 'To:' : 'CC:';
    return (
      <RemovableItem
        removeItem={() => removeEmail(index)}
        key={`email-${email}-${index}`}
        additionalClassNames={['email-list-item']}
      >
        <>
          <span className={'to'}>{toText}</span>
          <span className={'email'}>{email}</span>
        </>
      </RemovableItem>
    )
  })
  return (
    <section className={'email-list'}>
      {emailList}
    </section>
  );
}

export default EmailList;