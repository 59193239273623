import { FilterableItem } from '../components/common/filters/types/FilterTypes';

export enum LeadSource {
  LivesNearby = 'Lives nearby',
  WorksNearby = 'Works nearby',
};

export interface LeadNurtureMessage {
  message: string,
  sentDate: number | null,
  scheduledDate: number,
}

export interface LeadNurtureFlow {
  messages: LeadNurtureMessage[],
  stopNurtureIfResponse: boolean,
  stoppedDate: number | null,
  repliedDate: number | null,
  createdById: string,
}

export interface Lead extends FilterableItem {
  _id: string,
  firstName: string,
  lastName: string,
  mobileNumber: string,
  emailAddress: string,
  linkedInUrl: string,
  source: LeadSource,
  date: number,
  recipientId: string | null,
  nurtureFlow: LeadNurtureFlow | null,
  creationDate: number,
}