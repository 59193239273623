import { ReactElement } from 'react'

import ProgressBar from './ProgressBar'

interface GreenProgressBarProps {
  completionNumber: number,
  total: number,
  height: number,
}

function GreenProgressBar({ completionNumber, total, height } : Readonly<GreenProgressBarProps>): ReactElement<GreenProgressBarProps> {
  return <ProgressBar completionNumber={completionNumber} total={total} height={height} backgroundColor={'#FFFFFF'} borderColor={'rgba(0, 0, 0, 0.1)'} barColor={'#46CC6B'} />;
}

export default GreenProgressBar;
