const ONE_MINUTE_IN_MS = 60 * 1000;

export function getLastUpdateDate(lastUpdateDate: number): string {
  const dateDiff = Date.now() - lastUpdateDate;
  if (dateDiff < ONE_MINUTE_IN_MS) {
    return 'just now';
  }
  if (dateDiff < 60 * ONE_MINUTE_IN_MS) {
    const minutes = Math.floor(dateDiff / ONE_MINUTE_IN_MS);
    const minutesText = minutes === 1 ? 'minute' : 'minutes';
    return `${minutes} ${minutesText} ago`;
  }
  if (dateDiff < 24 * 60 * ONE_MINUTE_IN_MS) {
    const hours = Math.floor(dateDiff / (60 * ONE_MINUTE_IN_MS));
    const hoursText = hours === 1 ? 'hour' : 'hours';
    return `${hours} ${hoursText} ago`;
  }
  if (dateDiff < 7 * 24 * 60 * ONE_MINUTE_IN_MS) {
    const days = Math.floor(dateDiff / (24 * 60 * ONE_MINUTE_IN_MS));
    if (days === 1) {
      return 'yesterday';
    }
    return `${days} days ago`;
  }
  if (dateDiff < 30 * 24 * 60 * ONE_MINUTE_IN_MS) {
    const weeks = Math.floor(dateDiff / (7 * 24 * 60 * ONE_MINUTE_IN_MS));
    if (weeks === 1) {
      return 'last week';
    }
    return `${weeks} weeks ago`;
  }
  if (dateDiff < 365 * 24 * 60 * ONE_MINUTE_IN_MS) {
    const months = Math.floor(dateDiff / (30 * 24 * 60 * ONE_MINUTE_IN_MS));
    if (months === 1) {
      return 'last month';
    }
    return `${months} months ago`;
  }
  const years = Math.floor(dateDiff / (365 * 24 * 60 * ONE_MINUTE_IN_MS));
  if (years === 1) {
    return 'last year';
  }
  return `${years} years ago`;
}