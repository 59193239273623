import './RecoverClientsSettingsPage.scss';

import { ReactElement } from 'react';

import useAuth from '../../../context/AuthContext';
import { postUpdateBusinessEmailForwardingSettings, postUpdateBusinessLostClientCondition } from '../../../apis/BusinessApi';
import { EmailForwardingSettings, ReactivateLostClientConditions } from '../../../types/UserTypes';

import EmailForwarding from './settings/EmailForwarding';
import ReactivationConditions from './settings/ReactivationConditions';
import { useBusinessReactivateSettings } from './hooks/ReactivateClientSettingsHooks';
import LoadingRing from '../../common/loading/LoadingRing';

function RecoverClientsSettingsPage(): ReactElement {
  const { selectedBusinessId, setSelectedBusiness, business } = useAuth();
  const { emailForwardingSettings, setEmailForwardingSettings, reactivationConditions, setReactivationConditions, loading } = useBusinessReactivateSettings(selectedBusinessId);

  const updateReactivationConditions = async (reactivationConditions: ReactivateLostClientConditions[]) => {
    const validReactivationConditions = reactivationConditions
      .filter(({ attribute, condition, input }) => !!attribute && !!condition && (input !== '' && input !== null))
      .map(({ attribute, condition, input }) => ({ attribute, condition, input: typeof input === 'string' ? parseInt(input) : input }));
    setReactivationConditions(validReactivationConditions);
    let successResponse = { success: false };
    if (selectedBusinessId && business) {
      successResponse = await postUpdateBusinessLostClientCondition(selectedBusinessId, validReactivationConditions);
      setSelectedBusiness({ ...business, reactivateLostClientConditions: validReactivationConditions });
      return successResponse;
    }
    return successResponse
  };

  const updateEmailForwardingSettings = async (emailForwardingSettings: EmailForwardingSettings) => {
    setEmailForwardingSettings(emailForwardingSettings);
    if (selectedBusinessId && business) {
      await postUpdateBusinessEmailForwardingSettings(selectedBusinessId, emailForwardingSettings);
      setSelectedBusiness({ ...business, emailForwardingSettings });
    }
  }

  return (
    <article className={'reactivate-clients-settings-page'}>
      <section className={'page'}>
        <header className={'reactivate-clients-settings-page-header'}>
          <h2 className={'title'}>{'Settings'}</h2>
          <h3 className={'subtitle'}>{'View and edit your settings for reactivating lost clients.'}</h3>
        </header>
        {
          loading ? <LoadingRing color={'#000000'} /> : (
            <section className={'page-content'}>
              <ReactivationConditions updateReactivationConditions={updateReactivationConditions} reactivationConditions={reactivationConditions} setReactivationConditions={setReactivationConditions} />
              <EmailForwarding updateForwardingSettings={updateEmailForwardingSettings} emailForwardingSettings={emailForwardingSettings} />
            </section>
          )
        }
      </section>
    </article>
  );
}

export default RecoverClientsSettingsPage;