import './AccountActivityLogItem.scss';

import { ReactElement } from 'react';
import { ActivityType } from '../../../../types/BusinessTypes';
import { ActivityToIconMap } from '../AccountConstants';

interface AccountActivityLogItemItemProps {
  type: ActivityType,
  date: number,
  description: string,
  credits: number,
}

function AccountActivityLogItem({ type, date, description, credits }: Readonly<AccountActivityLogItemItemProps>): ReactElement {
  const icon = ActivityToIconMap[type];
  const creditText = credits === 1 ? `${credits} credit` : `${credits} credits`;
  return (
    <li className={'account-activity-log-item'}>
      <span className={'icon'}>
        <img className={'activity-icon'} src={icon} alt={`${type} icon`} />
      </span>
      <span className={'activity'}>{type}</span>
      <span className={'description'}>{description}</span>
      <span className={'date'}>{new Date(date).toLocaleDateString()}</span>
      <span className={'time'}>{new Date(date).toLocaleTimeString([], { hour: '2-digit', minute:'2-digit' }).toLocaleLowerCase().replace(' ', '')}</span>
      <span className={'credits'}>{creditText}</span>
    </li>
  );
}

export default AccountActivityLogItem;