import './IconButton.scss';

import OutlineButton from './OutlineButton';
import { IconButtonProps } from './ButtonConstants';
import LoadingRing from '../loading/LoadingRing';

function IconButton(
  {
    additionalClassNames = [],
    onClick,
    icon,
    disabled,
    loading,
    loadingContent = <LoadingRing color={'#0F172A'} />
  } : Readonly<IconButtonProps>)
{
  const classNames = ['icon-button', ...additionalClassNames];
  return <OutlineButton
    additionalClassNames={classNames}
    onClick={onClick}
    content={<img className={'icon'} src={icon} alt={''} />}
    disabled={disabled}
    loading={loading}
    loadingContent={loadingContent}
  />
}

export default IconButton;