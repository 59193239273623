import './LoginHeader.scss';

import YellowWorkRexLogo from '../assets/yellow-work-rex-logo.svg';

interface LoginHeaderProps {
  title: string,
  subtitle: string,
};

function LoginHeader({ title, subtitle } : LoginHeaderProps) {
  return (
    <header className={'login-header'}>
      <img alt={'yellow-rex-logo'} className={'login-rex-logo'} src={YellowWorkRexLogo} />
      <h1 className={'login-title'}>{title}</h1>
      <p className={'login-subtitle'}>{subtitle}</p>
    </header>
  )
}

export default LoginHeader;