import './ExportToCsvButton.scss';

import useAuth from '../../../../context/AuthContext';

import ExportButton from '../../../common/buttons/composite/ExportButton';
import { createCsv, downloadCsv } from '../../../../utils/ExportHelpers';
import { useBusinessAnalytics } from '../hooks/AnalyticsHooks';

function ExportToCsvButton() {
  const { selectedBusinessId, business } = useAuth();
  const { analytics, loading } = useBusinessAnalytics(selectedBusinessId);
  console.log(analytics);
  console.log(loading);

  const download = () => {
    if (analytics === null || !business) {
      return;
    }
    const csv = createCsv(Object.values(analytics));
    downloadCsv(csv, `${business.businessName}-lost-clients-analytics.csv`);
  }

  const disableButton = analytics === null || Object.keys(analytics).length === 0;
  return (
    <section className={'export-button'}>
      <ExportButton onClick={download} loading={loading} disabled={disableButton} />
    </section>
  )
};

export default ExportToCsvButton;