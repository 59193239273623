import { ReactElement } from 'react';
import { useNavigate } from 'react-router';

import { CaseStudy, CaseStudyType } from '../../dashboard/DashboardConstants';

import AboutPage from '../../common/AboutPage';

function ReactivateLostClientsAboutPage(): ReactElement {
  const { title, subtitle, previewUrl, setupUrl } = CaseStudy[CaseStudyType.ReactivateLostClients];
  const navigate = useNavigate();
  const onStartClick = () => navigate(setupUrl);
  return <AboutPage title={title} subtitle={subtitle} previewUrl={previewUrl} onStartClick={onStartClick} />
}

export default ReactivateLostClientsAboutPage;