import { ActivityType } from '../../../types/BusinessTypes';

import HANDWRITTEN_LETTER_ICON from './assets/handwritten-letter-icon.svg';
import REACTIVATION_ICON from './assets/reactivation-icon.svg';
import FOLLOW_UP_ICON from './assets/follow-up-icon.svg';

export const ActivityToCreditsMap = {
  [ActivityType.HandwrittenLetter]: 3,
  [ActivityType.Reactivation]: 5,
  [ActivityType.FollowUp]: 1,
}

export const ActivityToIconMap = {
  [ActivityType.HandwrittenLetter]: HANDWRITTEN_LETTER_ICON,
  [ActivityType.Reactivation]: REACTIVATION_ICON,
  [ActivityType.FollowUp]: FOLLOW_UP_ICON,
};