import { filterItems } from '../../../common/filters/helpers/FilterHelpers';
import { FilterItem } from '../../../common/filters/types/FilterTypes';
import { Address, AddressFilterAttributeMap } from '../HandwrittenLettersConstants';

export function filterAddresses(addresses: Address[] | null, filters: FilterItem[]) {
  return filterItems(addresses, filters, AddressFilterAttributeMap);
}

export function searchAddresses(addresses: Address[], searchText: string) {
  if (searchText.length === 0 || !addresses) {
    return addresses;
  }
  return addresses.filter(({ address, searchValue }) => {
    const addressMatch = address?.toLowerCase().includes(searchText.toLowerCase());
    const searchValueMatch = searchValue?.toLowerCase().includes(searchText.toLowerCase())
    return addressMatch || searchValueMatch;
  })
};
