import '../common/DashboardMetricItem.scss';

import { ReactElement } from 'react';

import InformationIconTooltip from '../../../common/tooltips/InformationIconTooltip';

import { getLastUpdateDate } from '../helpers/MetricHelper';

import UPDATE_ICON from '../assets/last-update-date-icon.svg';

interface DashboardMetricProps {
  label: string,
  count: number | string,
  icon: string,
  lastUpdateDate: number | null,
  tooltipText: string | null,
}

function DashboardMetricItem({ label, count, icon, lastUpdateDate, tooltipText } : Readonly<DashboardMetricProps>): ReactElement<DashboardMetricProps> {
  const lastUpdateText = lastUpdateDate ? getLastUpdateDate(lastUpdateDate) : '';
  return (
    <section className={'dashboard-metric-item'}>
      <section className={'metric'}>
        <section className={'metric-icon'}>
          <img className={'icon'} src={icon} alt={label} />
        </section>
        <section className={'metric-count'}>
          <section className={'metric-label'}>
            <span className={'metric-text'}>{label}</span>
            { tooltipText && <InformationIconTooltip tooltipText={tooltipText} />}
          </section>
          <section className={'metric-count'}>
            {count}
          </section>
        </section>
      </section>
      <section className={'last-update-date'}>
      { lastUpdateDate && (
        <>
          <img className={'update-icon'} src={UPDATE_ICON} alt={'Last updated icon'} />
          <span className={'last-update-text'}>{`Updated ${lastUpdateText}`}</span>
        </>
      )}
      </section>
    </section>
  );
}

export default DashboardMetricItem;