import HOME_ICON from './assets/home-icon.svg';
import REACTIVATE_CLIENTS_ICON from './assets/reactivate-clients-icon.svg';
import LIVING_NEARBY_ICON from './assets/living-nearby-icon.svg';
import WORKING_NEARBY_ICON from './assets/working-nearby-icon.svg';
import ACCOUNT_ICON from './assets/account-icon.svg'
import LEADS_ICON from './assets/leads-icon.svg';

import SELECTED_HOME_ICON from './assets/home-icon-selected.svg';
import SELECTED_REACTIVATE_CLIENTS_ICON from './assets/reactivate-clients-icon-selected.svg';
import SELECTED_LIVING_NEARBY_ICON from './assets/living-nearby-icon-selected.svg';
import SELECTED_WORKING_NEARBY_ICON from './assets/working-nearby-icon-selected.svg';
import SELECTED_ACCOUNT_ICON from './assets/account-icon-selected.svg'
import SELECTED_LEADS_ICON from './assets/leads-icon-selected.svg';

export enum SubNavigationItemKey {

  // Account
  ACCOUNT_DETAILS,
  ACCOUNT_USAGE_AND_BILLING,

  // Manage leads
  VIEW_LEADS,
  LEADS_SETTINGS,

  // Find new clients
  LIVING_NEARBY_ABOUT,
  LIVING_NEARBY_MANAGE,
  LIVING_NEARBY_SETTINGS,

  COMMERCIAL_OUTREACH_ABOUT,
  COMMERCIAL_OUTREACH_MANAGE,
  COMMERCIAL_OUTREACH_SETTINGS,

  // Retain new clients
  REACTIVATE_CLIENTS_ABOUT,
  REACTIVATE_CLIENTS_MANAGE,
  REACTIVATE_CLIENTS_SETTINGS,
};

export enum NavigationItemKey {
  HOME,
  ACCOUNT,
  LEADS,

  // Find new clients
  LIVING_NEARBY,
  COMMERCIAL_OUTREACH,

  // Retain new clients
  REACTIVATE_CLIENTS,
}

type NavigationSubItem = {
  key: SubNavigationItemKey,
  displayText: string,
  href: string,
}

export type NavigationItem = {
  key: NavigationItemKey,
  displayText: string,
  icon: string,
  selectedIcon: string,
  prefix: string,
  href: string,
  categoryHeader?: string,
  subItems: NavigationSubItem[],
  hasBottomDivider?: boolean,
}

export const CASE_STUDIES_NAVIGATION_ITEMS: NavigationItem[] = [
  {
    key: NavigationItemKey.HOME,
    displayText: 'Home',
    icon: HOME_ICON,
    selectedIcon: SELECTED_HOME_ICON,
    prefix: '/home',
    href: '/home',
    hasBottomDivider: false,
    subItems: []
  },
  {
    key: NavigationItemKey.ACCOUNT,
    displayText: 'Account',
    icon: ACCOUNT_ICON,
    selectedIcon: SELECTED_ACCOUNT_ICON,
    prefix: '/account',
    href: '/account',
    hasBottomDivider: true,
    subItems: [
      {
        key: SubNavigationItemKey.ACCOUNT_DETAILS,
        displayText: 'Account details',
        href: '/account',
      },
      {
        key: SubNavigationItemKey.ACCOUNT_USAGE_AND_BILLING,
        displayText: 'Usage and billing',
        href: '/account/billing',
      },
    ]
  },
  {
    key: NavigationItemKey.LEADS,
    displayText: 'Manage your leads',
    icon: LEADS_ICON,
    selectedIcon: SELECTED_LEADS_ICON,
    prefix: '/leads',
    href: '/leads',
    hasBottomDivider: false,
    subItems: [
      {
        key: SubNavigationItemKey.VIEW_LEADS,
        displayText: 'Your leads',
        href: '/leads',
      },
      {
        key: SubNavigationItemKey.LEADS_SETTINGS,
        displayText: 'Settings',
        href: '/leads/settings',
      },
    ]
  },
  {
    key: NavigationItemKey.LIVING_NEARBY,
    displayText: 'Living nearby',
    icon: LIVING_NEARBY_ICON,
    selectedIcon: SELECTED_LIVING_NEARBY_ICON,
    prefix: '/living-nearby',
    href: '/living-nearby/about',
    categoryHeader: 'Find new clients',
    subItems: [
      {
        key: SubNavigationItemKey.LIVING_NEARBY_ABOUT,
        displayText: 'What is it?',
        href: '/living-nearby/about',
      },
      {
        key: SubNavigationItemKey.LIVING_NEARBY_MANAGE,
        displayText: 'Manage',
        href: '/living-nearby/manage',
      },
      {
        key: SubNavigationItemKey.LIVING_NEARBY_SETTINGS,
        displayText: 'Settings',
        href: '/living-nearby/settings',
      },
    ],
  },
  {
    key: NavigationItemKey.COMMERCIAL_OUTREACH,
    displayText: 'Working nearby',
    icon: WORKING_NEARBY_ICON,
    selectedIcon: SELECTED_WORKING_NEARBY_ICON,
    prefix: '/commercial-outreach',
    href: '/commercial-outreach/about',
    subItems: [
      {
        key: SubNavigationItemKey.COMMERCIAL_OUTREACH_ABOUT,
        displayText: 'What is it?',
        href: '/commercial-outreach/about',
      },
      {
        key: SubNavigationItemKey.COMMERCIAL_OUTREACH_MANAGE,
        displayText: 'Manage',
        href: '/commercial-outreach/manage',
      },
      {
        key: SubNavigationItemKey.COMMERCIAL_OUTREACH_SETTINGS,
        displayText: 'Settings',
        href: '/commercial-outreach/settings',
      },
    ],
  },
  {
    key: NavigationItemKey.REACTIVATE_CLIENTS,
    displayText: 'Reactivate lost clients',
    icon: REACTIVATE_CLIENTS_ICON,
    selectedIcon: SELECTED_REACTIVATE_CLIENTS_ICON,
    prefix: '/reactivate-clients',
    href: '/reactivate-clients/about',
    categoryHeader: 'Retain your clients',
    subItems: [
      {
        key: SubNavigationItemKey.REACTIVATE_CLIENTS_ABOUT,
        displayText: 'What is it?',
        href: '/reactivate-clients/about',
      },
      {
        key: SubNavigationItemKey.REACTIVATE_CLIENTS_MANAGE,
        displayText: 'Manage',
        href: '/reactivate-clients/manage',
      },
      {
        key: SubNavigationItemKey.REACTIVATE_CLIENTS_SETTINGS,
        displayText: 'Settings',
        href: '/reactivate-clients/settings',
      },
    ],
  },
]