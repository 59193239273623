import { filterItems } from '../../../common/filters/helpers/FilterHelpers';
import { FilterItem } from '../../../common/filters/types/FilterTypes';
import { LinkedInUser, LinkedInUserFilterAttributeMap } from '../CommercialOutreachConstants';

export function filterLinkedInUsers(linkedInUsers: LinkedInUser[] | null, filters: FilterItem[]) {
  return filterItems(linkedInUsers, filters, LinkedInUserFilterAttributeMap);
}

export function searchLinkedInUsers(linkedInUsers: LinkedInUser[], searchText: string) {
  if (searchText.length === 0 || !linkedInUsers) {
    return linkedInUsers;
  }
  return linkedInUsers.filter(({ fullName, companyName, emailAddress }) => {
    const fullNameMatch = fullName?.toLowerCase().includes(searchText.toLowerCase());
    const companyNameMatch = companyName?.toLowerCase().includes(searchText.toLowerCase())
    const emailAddressMatch = emailAddress?.toLowerCase().includes(searchText.toLowerCase())
    return fullNameMatch || companyNameMatch || emailAddressMatch;
  })
};
