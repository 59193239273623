import './DateInput.scss';
import 'react-datepicker/dist/react-datepicker.css';

import DatePicker from 'react-datepicker';

interface DateInputProps {
  minDate?: undefined | Date,
  selectedDate: Date | null,
  setDate: (input: number) => void,
}

// TODO: Update date formats for locality US vs Global
function DateInput({ minDate = undefined, selectedDate, setDate } : DateInputProps) {

  const onChange = (selectedDate: Date | null) => {
    const formattedDate = selectedDate ? selectedDate.getTime() : Date.now();
    setDate(formattedDate);
  }
  return (
    <section className={'time-period-picker'}>
      <DatePicker
        minDate={minDate}
        selected={selectedDate}
        onChange={onChange}
        dateFormat={'dd/MM/yy'}
        formatWeekDay={nameOfDay => nameOfDay.substring(0, 3)}
        placeholderText={'dd/mm/yy'}
        showPopperArrow={false}
      />
    </section>
  );
}

export default DateInput;