import { useState } from 'react';

function getStorageValue<T>(key: string, defaultValue: T): T {
  const storedValue = localStorage.getItem(key);
  let initialValue: T | null =  null;
  if (storedValue) {
    try {
      initialValue = JSON.parse(storedValue);
    }
    catch {
      initialValue = null;
    }
  }
  console.log({ initialValue });
  return initialValue || defaultValue;
}

export function useLocalStorage<T>(key: string, defaultValue: T): [T, (value: T) => void, () => void] {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });
  
  function clearValue() {
    localStorage.removeItem(key);
  }

  const setStorageValue = (storageValue: T) => {
    setValue(storageValue);
    console.log({ storageValue });
    localStorage.setItem(key, JSON.stringify(storageValue));
  }

  return [value, setStorageValue, clearValue];
};

export enum LocalStorageKey {
  SelectedBusinessId = 'SelectedBusinessId',
}