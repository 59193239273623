import './AccountActivityLog.scss';

import { ReactElement } from 'react';

import { BusinessEvent } from '../../../../types/BusinessTypes';
import { ActivityToCreditsMap } from '../AccountConstants';

import AccountActivityLogItem from './AccountActivityLogItem';

interface AccountActivityLogProps {
  activities: BusinessEvent[],
  isEmpty: boolean,
}

function AccountActivityLog({ activities, isEmpty }: Readonly<AccountActivityLogProps>): ReactElement {
  const activityList = activities.map(({ eventType, creationDate, eventDescription }) => {
    const credits = ActivityToCreditsMap[eventType];
    return (
      <AccountActivityLogItem
        key={`${eventType}-${creationDate}`}
        type={eventType}
        date={creationDate}
        description={eventDescription}
        credits={credits}
      />
    );
  });
  return (
    <section className={'account-activity-log'}>
      <header className={'activity-log-header'}>
        <h3 className={'activity-log-title'}>{'Activity log'}</h3>
      </header>
      <ul className={'activity-log-list'}>
        { isEmpty ? <span className={'empty-activity-log'}>{'No activity to display'}</span> : activityList }
      </ul>
    </section>
  );
}

export default AccountActivityLog;