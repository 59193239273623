import './Filter.scss';

import { useState } from 'react';

import { OptionType } from '../dropdowns/DropdownConstants';

import OutlineButton from '../buttons/OutlineButton';
import LightPurpleTag from '../tags/LightPurpleTag';

import { FilterItem, FilterAttributeMapType } from './types/FilterTypes';

import AddFilterModal from './common/AddFilterModal';

interface FilterProps<T> {
  rows: T[] | null,
  filteredRows: T[] | null,
  filters: FilterItem[],
  setFilters: React.Dispatch<React.SetStateAction<FilterItem[]>>,
  searchText: string,
  filterAttributeMap: FilterAttributeMapType,
  filterOptions: OptionType<string>[],
};

function Filter<T>({ rows, filteredRows, filters, setFilters, filterAttributeMap, filterOptions } : FilterProps<T>) {
  const resetFilters = () => setFilters([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const filteredCount = rows && filteredRows ? `(${filteredRows.length}/${rows.length})` : '';
  const selectedFilters = filters.length > 0;

  // Not sure how to get around this issue of attribute potentially being null.
  // If I remove the null check I get this error: Type 'null' cannot be used as an index type
  const filterTags = filters
    .map(({ attribute }, index) => {
      if (attribute) {
        return <LightPurpleTag key={`${attribute}-${index}`} content={filterAttributeMap[attribute].displayText} />;
      }
      return null;
    })

  const confirmFilters = (filters: FilterItem[]) => {
    const populatedFilters = filters.filter((filter: FilterItem) => filter.attribute);
    setFilters(populatedFilters);
  };

  const filterText = `Filter${filters.length > 0 ? ` (${filters.length})` : ''}`;
  return (
    <section className={'generic-filter'}>
      <OutlineButton additionalClassNames={['filter-button']} content={filterText} onClick={() => setFilterOpen(true)} />
      {filterTags}
      {selectedFilters && <span className={'filter-count'}>{filteredCount}</span>}
      {filterOpen && (
        <AddFilterModal
          parentFilters={filters}
          close={() => setFilterOpen(false)}
          confirm={confirmFilters}
          resetFilters={resetFilters}
          filterAttributeMap={filterAttributeMap}
          filterOptions={filterOptions}
        />
      )}
    </section>
  );
}

export default Filter;