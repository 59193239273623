import './EmailForwarding.scss';

import { useState } from 'react';

import { EMAIL_FORWARDING_OPTIONS, EmailForwardingSettings, EmailForwardingPreferences } from '../../../../types/UserTypes';

import Dropdown from '../../../common/dropdowns/Dropdown';
import TrashIconButton from '../../../common/buttons/composite/TrashIconButton';
import PrimaryInput from '../../../common/inputs/PrimaryInput';
import PrimaryButton from '../../../common/buttons/PrimaryButton';

// TODO: Delete this

interface EmailForwardingProps {
  emailForwardingSettings: EmailForwardingSettings,
  updateForwardingSettings: (emailForwardingSettings: EmailForwardingSettings) => void
}

function EmailForwarding({ updateForwardingSettings, emailForwardingSettings }: EmailForwardingProps) {
  const { emailToSmsPreference, emailForwardingList } = emailForwardingSettings;
  const [emailToForward, setEmailToForward] = useState('');

  const updateEmailToSmsPreference = (updatedEmailToSmsPreference: EmailForwardingPreferences) => {
    const updatedEmailForwardingSettings = { ...emailForwardingSettings, emailToSmsPreference: updatedEmailToSmsPreference };
    updateForwardingSettings(updatedEmailForwardingSettings);
  }

  const updateEmailForwardingList = (updatedupdateEmailForwardingList: string[]) => {
    const updatedEmailForwardingSettings = { ...emailForwardingSettings, emailForwardingList: updatedupdateEmailForwardingList };
    updateForwardingSettings(updatedEmailForwardingSettings);
  }

  const emailForwardingListNode = emailForwardingList.map((emailToForward, index) => {
    const deleteIndex = () => {
      const forwardingListWithDeletions = emailForwardingList.filter((_, i) => i !== index);
      updateEmailForwardingList(forwardingListWithDeletions);
    }
    const receiverPosition = index === 0 ? 'to:' : 'cc:';
    return (
      <section className={'email-to-forward'} key={emailToForward}>
        <span className={'index'}>{receiverPosition}</span>
        <span className={'name'}>{emailToForward}</span>
        <TrashIconButton onClick={deleteIndex} />
      </section>
    );
  });

  const addEmail = () => {
    if (emailForwardingList.length < 3) {
      const newForwardingList = [ ...emailForwardingList, emailToForward ];
      setEmailToForward('');
      updateEmailForwardingList(newForwardingList)
    }
  }

  const emailForwardingLimitReached = emailForwardingList.length >= 3;

  return (
    <section className={'email-forwarding'}>
      <section className={'section-container'}>
        <h4 className={'input-title'}>{'Email-to-SMS forwarding and replies'}</h4>
        <h5 className={'input-subtitle'}>{'Choose when and who to forward messages to'}</h5>
        <h5 className={'sub-input-title'}>{'Forwarding preferences'}</h5>
        <section className={'input-container'}>
          <Dropdown
            defaultDisplayText={'Forwarding preferences'}
            options={EMAIL_FORWARDING_OPTIONS}
            select={updateEmailToSmsPreference}
            selected={emailToSmsPreference}
          />
        </section>
      </section>
      <section className={'section-container'}>
        <h4 className={'input-title'}>{'Forward to:'}</h4>
        <h5 className={'input-subtitle'}>{'You can add up to 3 recipients'}</h5>
        <section className={'input-container'}>
          <label className={'input-label'} htmlFor={'email-to-forward-input'}>{'Email:'}</label>
          <PrimaryInput
            text={emailToForward}
            setText={setEmailToForward}
            inputType={'string'}
            placeholder={'Type email address'}
            id={'email-to-forward-input'}
          />
          <PrimaryButton content={'Add'} onClick={addEmail} disabled={emailForwardingLimitReached} />
        </section>
      </section>
      <section className={'section-container'}>
        <section className={'email-forwarding-list'}>
          {emailForwardingListNode}
        </section>
      </section>
    </section>
  )
}

export default EmailForwarding;