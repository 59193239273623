import './DashboardMetrics.scss';

import { ReactElement } from 'react';

import useAuth from '../../../../context/AuthContext';

import LoadingRing from '../../../common/loading/LoadingRing';

import {
  DefaultCommercialOutreachMetrics,
  DefaultHandwrittenLetterMetrics,
  DefaultReactivateLostClientsMetrics,
  ReactivateLostClientsMetricItems,
  HandwrittenLetterMetricItems,
  CommercialOutreachMetricItems
} from '../DashboardConstants';
import { useBusinessMetrics } from '../hooks/DashboardHooks';
import DashboardMetricListContainer from '../common/DashboardMetricListContainer';

function DashboardMetrics(): ReactElement {
  const { selectedBusinessId } = useAuth();
  const { loading, metrics } = useBusinessMetrics(selectedBusinessId);
  const handwrittenLetterMetrics = metrics?.handwrittenLetterMetrics || DefaultHandwrittenLetterMetrics;
  const reactivateLostClientsMetrics = metrics?.reactivateLostClientsMetrics || DefaultReactivateLostClientsMetrics;
  const commercialOutreachMetrics = metrics?.commercialOutreachMetrics || DefaultCommercialOutreachMetrics;

  if (loading) {
    return (
      <section className={'dashboard-metrics loading'}>
        <LoadingRing color={'#000000'} />
      </section>
    );
  }
  return (
    <section className={'dashboard-metrics'}>
      <DashboardMetricListContainer
        keyId={'living-nearby'}
        metricItems={HandwrittenLetterMetricItems}
        metrics={handwrittenLetterMetrics}
        title={'Living nearby'}
      />
      <DashboardMetricListContainer
        keyId={'working-nearby'}
        metricItems={CommercialOutreachMetricItems}
        metrics={commercialOutreachMetrics}
        title={'Working nearby'}
      />
      <DashboardMetricListContainer
        keyId={'reactivate-lost-clients'}
        metricItems={ReactivateLostClientsMetricItems}
        metrics={reactivateLostClientsMetrics}
        title={'Reactivate lost clients'}
      />
    </section>
  )
}

export default DashboardMetrics;