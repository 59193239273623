import { getData, postData } from './Api';

import { Address } from '../components/case-studies/living-nearby/HandwrittenLettersConstants';
import { SuccessResponse } from './types/ResponseTypes';

const PostCaseStudyUrls = {
  RequestManyAddressesForBusinessUrl: '/api/handwritten-letter/request',
  AddressesForBusinessUrl: '/api/handwritten-letter/addresses',
  SendLettersUrl: '/api/handwritten-letter/send',
};

const {
  AddressesForBusinessUrl,
  RequestManyAddressesForBusinessUrl,
  SendLettersUrl,
} = PostCaseStudyUrls;

// Request interfaces

/**
 * Request for requesting a list of addresses to be added to a particular business.
 * Done through a flatfile upload.
 */
interface RequestHandWrittenLetterAddressRequest {
  addresses: Address[],
  businessId: string,
};

/**
 * Request for sending out a list of letters to the list of address ids specified.
 * Sets the addresses' shouldSend field to true and adds records to the "1C. Request Letters" table.
 */
interface SendLettersRequest {
  addressIds: string[],
};

// Response interfaces

/**
 * Response from reqeusting a list of addresses to be added to a particular business.
 * Returns a list of addresses that were successfully added.
 */
interface RequestHandWrittenLetterAddressResponse extends SuccessResponse {
  addresses: Address[]
};

/**
 * Response from fetching all addresses for a particular business.
 */
interface HandwrittenLetterAddressResponse {
  addresses: Address[]
};

/**
 * Fetches all addresses for a particular business id.
 */
export async function getAddressesForBusiness(businessId: string): Promise<HandwrittenLetterAddressResponse> {
  return getData<HandwrittenLetterAddressResponse>(`${AddressesForBusinessUrl}/${businessId}`);
}

/**
 * Requests addresses.
 */
export async function postRequestAddresses(addresses: Address[], businessId: string): Promise<RequestHandWrittenLetterAddressResponse> {
  return await postData<RequestHandWrittenLetterAddressRequest, RequestHandWrittenLetterAddressResponse>(
    RequestManyAddressesForBusinessUrl, { addresses, businessId }
  );
}

/**
 * Updates all addresses to sent.
 */
export async function postSendLettersToAddresses(addressIds: string[]): Promise<void> {
  await postData<SendLettersRequest, void>(
    SendLettersUrl, { addressIds }
  );
}
