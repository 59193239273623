import './HandwrittenLettersTable.scss';

import { useState } from 'react';

import { postSendLettersToAddresses } from '../../../../apis/LettersApi';
import useAuth from '../../../../context/AuthContext';

import Table, { TableHeaderProps } from '../../../common/tables/Table';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import PaginationBar from '../../../common/tables/PaginationBar';
import InformationIconTooltip from '../../../common/tooltips/InformationIconTooltip';
import GreenTag from '../../../common/tags/GreenTag';
import LightPurpleTag from '../../../common/tags/LightPurpleTag';
import DarkPurpleTag from '../../../common/tags/DarkPurpleTag';
import ProgressLevels from '../../../common/bars/ProgressLevels';

import { Address, LetterStatus } from '../HandwrittenLettersConstants';
import { getProximityText } from '../helpers/DistanceHelpers';

const { Available, Requested, Complete } = LetterStatus;

const TABLE_HEADERS: TableHeaderProps[] = [
  { displayText: 'Address', key: 'address' },
  { displayText: 'Search value', key: 'searchValue' },
  { displayText: 'Proximity', key: 'proximity' },
  { displayText: 'Status', key: 'status' },
  { displayText: 'Property value', key: 'propertyValue' },
  { displayText: 'Type', key: 'addressType' },
  { displayText: 'Transaction date', key: 'transactionDate' },
  { displayText: 'Send', key: 'send' },
];

interface HandwrittenLettersTableProps {
  addresses: Address[],
  setAddresses: (addresses: Address[]) => void,
  cursor: number,
  setCursor: React.Dispatch<React.SetStateAction<number>>
  selectedAddresses: Address[],
  setSelectedAddresses: React.Dispatch<React.SetStateAction<Address[]>>
  hasAddressesToSend: boolean,
};

const getStatusNode = (status: LetterStatus) => {
  switch (status) {
    case Complete:
      return <GreenTag content={status} additionalClassNames={['tag-cell']} />;
    case Requested:
      return <LightPurpleTag content={status} additionalClassNames={['tag-cell']} />;
    case Available:
      return <DarkPurpleTag content={status} additionalClassNames={['tag-cell']} />;
    default:
      return null;
  }
};

const getSendNode = (status: LetterStatus, shouldSend: boolean, sendLetter: () => Promise<void>, loading: boolean) => {
  switch (status) {
    case Complete:
      return <PrimaryButton content={'Sent'} additionalClassNames={['button-cell']} disabled={true} loading={loading} />;
    case Requested:
      if (shouldSend) {
        return <PrimaryButton content={'In progress'} additionalClassNames={['button-cell']} disabled={true} loading={loading} />;
      }
      return <PrimaryButton content={'Send'} additionalClassNames={['button-cell']} onClick={sendLetter} disabled={true} />;
    case Available:
      if (shouldSend) {
        return <PrimaryButton content={'In progress'} additionalClassNames={['button-cell']} disabled={true} loading={loading} />;
      }
      return <PrimaryButton content={'Send'} additionalClassNames={['button-cell']} onClick={sendLetter} loading={loading} />;
    default:
      return null;
  }
};

const HANDWRITTEN_LETTERS_MAX_ROWS = 5;

const TOTAL_PROXIMITY_LEVELS = 4;

function HandwrittenLettersTable({ addresses, setAddresses, cursor, setCursor, selectedAddresses, setSelectedAddresses, hasAddressesToSend } : Readonly<HandwrittenLettersTableProps> ) {
  const { business } = useAuth();
  const [loadingIndex, setLoadingIndex] = useState<number | null>(null);
  const rows = addresses.map(({ _id, address, searchValue, status, propertyValue, transactionDate, addressType, shouldSend, addressLocation }, index) => {
    const addressNode = <section className={'two-cell long-text'}>{address}</section>;
    const searchValueNode = <section className={'one-and-half-cell short-text'}>{searchValue}</section>;
    const proximity = getProximityText(business?.businessLocation, addressLocation);
    const proximityNode = proximity ? (
      <section className={'proximity-cell'}>
        <ProgressLevels filled={proximity.level} total={TOTAL_PROXIMITY_LEVELS} />
        <InformationIconTooltip tooltipText={proximity.tooltipText} />
      </section>
    ) : <section className={'one-cell'} />;
    const statusNode = getStatusNode(status);
    const propertyValueNode = <section className={'one-cell long-header'}>{propertyValue}</section>;
    const transactionDateNode = <section className={'one-cell long-header'}>{transactionDate}</section>;
    const addressTypeNode = <section className={'one-cell'}>{addressType}</section>;
    const loadingSentButton = index === loadingIndex;
    
    const sendClick = async () => {
      setLoadingIndex(index);
      await postSendLettersToAddresses([_id]);
      const newAddresses = [...addresses];
      newAddresses[index] = { ...newAddresses[index], shouldSend: true };
      setLoadingIndex(null);
      setAddresses(newAddresses);
    }
    const sendNode = <section className={'one-button-cell'}>{getSendNode(status, shouldSend, sendClick, loadingSentButton)}</section>;
    const currentAddress = addresses[index];
    const selectRow = () => {
      if (selectedAddresses.includes(currentAddress)) {
        setSelectedAddresses(selectedAddresses.filter((address) => address !== currentAddress));
      }
      else {
        setSelectedAddresses([...selectedAddresses, currentAddress]);
      }
    }
    return {
      cells: [
        { key: 'address', value: addressNode },
        { key: 'searchValue', value: searchValueNode },
        { key: 'proximity', value: proximityNode },
        { key: 'status', value: statusNode },
        { key: 'propertyValue', value: propertyValueNode },
        { key: 'addressType', value: addressTypeNode },
        { key: 'transactionDate', value: transactionDateNode },
        { key: 'send', value: sendNode },
      ],
      selected: selectedAddresses.includes(addresses[index]),
      isSelectorDisabled: status !== LetterStatus.Available,
      selectRow,
    };
  });
  const selectAllRows = () => {
    setSelectedAddresses(selectedAddresses.length === addresses.length ? [] : addresses);
  }
  return (
    <>
      <section className={'handwritten-letters-table-container'}>
        <section className={'table-container'}>
          <Table
            additionalClassNames={['handwritten-letters-table']}
            headers={TABLE_HEADERS}
            rows={rows}
            cursor={cursor}
            maxRows={HANDWRITTEN_LETTERS_MAX_ROWS}
            selectAllRows={selectAllRows}
            hasSelector={hasAddressesToSend}
            hasCounter={true}
          />
        </section>
        <section className={'pagination-bar-container'}>
          <PaginationBar
            cursor={cursor}
            setCursor={setCursor}
            maxRowsPerPage={HANDWRITTEN_LETTERS_MAX_ROWS}
            totalRows={rows.length}
          />
        </section>
      </section>
    </>
  )
}

export default HandwrittenLettersTable;

