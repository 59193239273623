import parser from 'any-date-parser';

import { FilterConditionMap } from '../FilterConstants';
import { ComparableTypes, FilterAttributeMapType, FilterItem, FilterableItemType, FilterType, FilterableItem } from '../types/FilterTypes';

const { NUMBER, DATE } = FilterType;

/** Format numeric and date types. */
function validateAndFormatNumeric(value: any, valueType: FilterType, countryCode?: FilterableItemType) {
  if (typeof value === 'string' && valueType === DATE) {
    const date = typeof countryCode === 'string' ? parser.fromString(value, countryCode) : parser.fromString(value);
    return typeof date.getTime === 'function' ? date.getTime() : null;
  }
  if (typeof value === 'string' && valueType === NUMBER) {
    return value.replace(/\D/g, '');
  }
  return value;
}

/** Filters a list of items based on a list of filters. */
export function filterItems<T extends FilterableItem>(items: T[] | null, filters: FilterItem[], filterAttributeMap: FilterAttributeMapType): T[] {
  if (!items) {
    return [];
  }
  return items.filter((item: T) => {

    // Only addresses have country code so far - perhaps we need to a way to handle localization differently
    const { countryCode = undefined } = item;

    // Only compute filters with conditions
    const populatedFilters = filters.filter((filter: FilterItem) => filter.condition);
    for (const filter of populatedFilters) {
      const { attribute, condition, input } = filter;
      if (!attribute || !condition) {
        return false;
      }

      const attributeType = filterAttributeMap[attribute].type;
      const conditionObject = FilterConditionMap[attributeType][condition];

      const recordVal = Object.hasOwn(item, attribute)
        ? validateAndFormatNumeric(item[attribute], attributeType, countryCode)
        : null;

      if (!conditionObject) return false;

      let filterResult = false;

      if (ComparableTypes.includes(attributeType)) {
        filterResult = conditionObject.filterFunction(recordVal, input);
      }

      if (!filterResult) {
        return false;
      }
    }

    return true;
  });
}