export function brToNewLine(str: string) {
  return str.replace(/<br\s*\/?>/mg,"\n");
};

export function capitaliseString(str: string) {
  if (!str || str.length === 0) {
    return str;
  }
  return `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`;
};

export function capitaliseStringParts(str: string) {
  if (!str) {
    return str;
  }
  const strParts = str.split(' ');
  const capitalisedStrParts = strParts.map(strPart => capitaliseString(strPart));
  return capitalisedStrParts.join(' ');
}