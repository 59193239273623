import { Message } from './MessageConstants';

export enum FlatFileTypeText {
  ClientsText = 'Clients',
  InteractionsText = 'Client interactions',
};

export enum FlatFileTypeKey {
  ClientsKey = 'clients',
  InteractionsKey = 'clientInteractions',
}

const { ClientsText, InteractionsText } = FlatFileTypeText;
const { ClientsKey, InteractionsKey } = FlatFileTypeKey;

export const FLATFILE_UPLOAD_TYPE = [
  { displayText: ClientsText, value: ClientsKey },
  { displayText: InteractionsText, value: InteractionsKey },
];

export interface RecoverClientMessage extends Message {
  _id: string,
  trigger: string,
  triggerDate: number,
  creationDate: number,
  queuedForFineTuning: boolean,
  fineTuneParameters: object,
  businessId: null,
  deletionDate: null,
}

export interface EnrichedRecoverClientMessage extends RecoverClientMessage {
  lastMessageReceivedDate: number | null,
  lastMessageSentDate: number | null,
  lastInteractionDate: number | null,
  lastAppointmentDate: number | null,
  lastTransactionDate: number | null,
  lifetimeValue: number | null,
};