import Modal from '../../../common/modals/Modal';
import ConfirmationModalContent from '../../../common/modals/ConfirmationModalContent';

interface DeleteMessageModalProps {
  cancel: () => void,
  confirm: () => void,
};

function DeleteMessageModal({ cancel, confirm } : Readonly<DeleteMessageModalProps>) {
  return (
    <Modal additionalClassNames={['delete-message-modal']}>
      <ConfirmationModalContent
        title={'Are you absolutely sure?'}
        subtitle={'This action cannot be undone. This will delete this message.'}
        cancel={cancel}
        confirm={confirm}
      />
    </Modal>
  )
};

export default DeleteMessageModal;