import './FineTuneModal.scss';

import { useState } from 'react';

import useAuth from '../../../../context/AuthContext';
import { postQueueMessageToFineTune } from '../../../../apis/MessageApi';

import Modal from '../../../common/modals/Modal';
import TextAreaInput from '../../../common/inputs/TextAreaInput';
import OutlineButton from '../../../common/buttons/OutlineButton';
import PrimaryButton from '../../../common/buttons/PrimaryButton';

import { Message } from '../MessageConstants';

interface FineTuneModalProps {
  messages: Message[],
  close: () => void,
  confirm: () => void,
};

function FineTuneModal({ messages, close, confirm } : Readonly<FineTuneModalProps>) {
  const { selectedBusinessId } = useAuth();
  const [fineTunePrompt, setFineTunePrompt] = useState('');

  const confirmTuning = async () => {
    if (!selectedBusinessId) {
      return;
    }
    await postQueueMessageToFineTune(fineTunePrompt, messages, selectedBusinessId);
    confirm();
  }

  return (
    <Modal additionalClassNames={['fine-tune-modal']}>
      <section className={'fine-tune-modal-content'} >
        <h4 className={'title'}>{'Make adjustments to your message'}</h4>
        <h5 className={'subtitle'}>{'Fine tune your message by changing or replacing details in the message.'}</h5>
        <TextAreaInput text={fineTunePrompt} setText={setFineTunePrompt} placeholder={'Start typing your desired changes here...'} />
        <section className={'buttons'}>
          <OutlineButton content={'Cancel'} onClick={close} />
          <PrimaryButton content={'Confirm'} onClick={confirmTuning} />
        </section>
      </section>
    </Modal>
  )
};

export default FineTuneModal;