import './DashboardMetricListContainer.scss';

import { ReactElement } from 'react';

import { Metrics } from '../../../../types/MetricTypes';

import { DashboardMetric } from '../DashboardConstants';

import DashboardMetricItem from './DashboardMetricItem';

interface DashboardMetricProps {
  metricItems: DashboardMetric[]
  metrics: Metrics,
  keyId: string,
}

function DashboardMetricList({ metricItems, metrics, keyId } : Readonly<DashboardMetricProps>): ReactElement<DashboardMetricProps> {
  const metricList = metricItems.map(({ label, icon, tooltipText, fieldName }) => {
    const count = metrics[fieldName].value;
    const lastUpdateDate = metrics[fieldName].lastUpdateDate;
    return (
      <DashboardMetricItem
        key={`${keyId}-${fieldName}`}
        label={label}
        icon={icon}
        tooltipText={tooltipText}
        count={count}
        lastUpdateDate={lastUpdateDate}
      />
    );
  });
  return <>{metricList}</>
}

export default DashboardMetricList;