import './CommercialOutreachTable.scss';

import { useState } from 'react';

import useAuth from '../../../../context/AuthContext';
import { ModalAction } from '../../../people/PeopleConstants';
import { useMediaQuery } from '../../../../hooks/MediaHooks';

import ProgressLevels from '../../../common/bars/ProgressLevels';
import InformationIconTooltip from '../../../common/tooltips/InformationIconTooltip';
import PaginationBar from '../../../common/tables/PaginationBar';
import DeleteMessageModal from '../../reactivate-clients/modals/DeleteMessageModal';
import TrashIconButton from '../../../common/buttons/composite/TrashIconButton';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import GreenTag from '../../../common/tags/GreenTag';
import DarkPurpleTag from '../../../common/tags/DarkPurpleTag';
import LightPurpleTag from '../../../common/tags/LightPurpleTag';
import RoundedLetterTag from '../../../common/tags/RoundedLetterTag';
import ConfirmMessageSendModal from '../../reactivate-clients/modals/SendMessageModal';
import Table, { TableHeaderProps } from '../../../common/tables/Table';

import { getProximityText } from '../../living-nearby/helpers/DistanceHelpers';

import { CommercialOutreachStatus, LinkedInUser } from '../CommercialOutreachConstants';

const COMMERCIAL_OUTREACH_TABLE_HEADERS: TableHeaderProps[] = [
  { displayText: 'Full name', key: 'fullName' },
  { displayText: 'Company', key: 'companyName' },
  { displayText: 'Distance', key: 'distance' },
  { displayText: 'Outreach status', key: 'status' },
  { displayText: 'LinkedIn URL', key: 'linkedInUrl' },
  { displayText: 'Email', key: 'emailAddress' },
  { displayText: 'Send request', key: 'send' },
  { displayText: 'Delete', key: 'delete' },
];

const RECIPIENTS_MAX_ROWS = 5;
const RECIPIENTS_SMALL_SCREEN_MAX_ROWS = 3;
const TOTAL_DISTANCE_LEVELS = 4;

const getStatusNode = (status: CommercialOutreachStatus) => {
  switch (status) {
    case CommercialOutreachStatus.Available:
      return <GreenTag content={status} additionalClassNames={['tag-cell']} />;
    case CommercialOutreachStatus['In progress']:
      return <LightPurpleTag content={status} additionalClassNames={['tag-cell']} />;
    case CommercialOutreachStatus.Completed:
      return <DarkPurpleTag content={status} additionalClassNames={['tag-cell']} />;
    default:
      return null;
  }
};

interface CommercialOutreachTableProps {
  linkedInUsers: LinkedInUser[],
  setLinkedInUsers: (linkedInUsers: LinkedInUser[]) => void,
  selectedLinkedInUsers: LinkedInUser[],
  setSelectedLinkedInUsers: React.Dispatch<React.SetStateAction<LinkedInUser[]>>,
  cursor: number,
  setCursor: React.Dispatch<React.SetStateAction<number>>,
  hasMessagesToSend: boolean,
}

const { Delete, Send } = ModalAction;

function CommercialOutreachTable({ selectedLinkedInUsers, setSelectedLinkedInUsers, linkedInUsers = [], setLinkedInUsers, cursor, setCursor, hasMessagesToSend } : CommercialOutreachTableProps) {
  const { business } = useAuth();
  const isTablet = useMediaQuery('(max-height: 720px)');
  const [selectedMessageId, setSelectedMessageId] = useState<string | null>(null);
  const [modalAction, setModalAction] = useState<ModalAction | null>(null);
  const selectAllRows = () => {
    if (selectedLinkedInUsers.length === linkedInUsers.length) {
      setSelectedLinkedInUsers([]);
    }
    else {
      setSelectedLinkedInUsers(linkedInUsers);
    }
  };
  const headers = [
    ...COMMERCIAL_OUTREACH_TABLE_HEADERS
  ]

  const rows = linkedInUsers
    .map((linkedInUser) => {
      const { _id, fullName, companyName, addressLocation, status, linkedInUrl, emailAddress } = linkedInUser;
      
      const isSelected = !!selectedLinkedInUsers.find((row) => row._id === _id);
      const selectRow = () => {
        if (isSelected) {
          const filteredRows = selectedLinkedInUsers.filter((row) => row._id !== _id);
          setSelectedLinkedInUsers(filteredRows);
        }
        else {
          setSelectedLinkedInUsers([ linkedInUser, ...selectedLinkedInUsers ]);
        }
      }
      const fullNameNode = <section className={'two-cell short-text'} >{fullName.trim()}</section>
      const companyNameNode = <section className={'two-cell short-text'} >{companyName.trim()}</section>
      const distance = getProximityText(business?.businessLocation, addressLocation);
      const distanceNode = distance ? (
        <section className={'distance-cell'}>
          <ProgressLevels filled={distance.level} total={TOTAL_DISTANCE_LEVELS} />
          <InformationIconTooltip tooltipText={distance.tooltipText} />
        </section>
      ) : (
        <section className={'one-cell'}>
          {'No data found'}
        </section>
      );
      const statusNode = <section className={'one-button-cell short-text'} >{getStatusNode(status)}</section>
      const linkedInUrlNode = linkedInUrl ? (
        <section className={'two-cell short-text linked-in-url-cell'}>
          <RoundedLetterTag content={fullName.charAt(0).toUpperCase()} />
          <a className={'linked-in-url-tag'} href={linkedInUrl} target={'_blank'} rel={'noopener noreferrer'}>{`${fullName}'s LinkedIn`}</a>
        </section>
      ) : (
        <section className={'one-cell'}>
          {'No data found'}
        </section>
      );
      const emailAddressNode = <section className={'two-cell short-text'} >{emailAddress.trim()}</section>

      const openModal = (modalAction: ModalAction) => {
        setSelectedMessageId(_id);
        setModalAction(modalAction);
      }

      const isAvailable = status === CommercialOutreachStatus.Available;
      const sendNode = <section className={'one-button-cell'}><PrimaryButton onClick={() => openModal(Send)} content={'Send'} disabled={!isAvailable} additionalClassNames={['tag-cell']} /></section>;
      const deleteNode = <TrashIconButton onClick={() => openModal(Delete)} disabled={!isAvailable} additionalClassNames={['one-cell']} />;
      return {
        cells: [
          { key: 'fullName', value: fullNameNode },
          { key: 'companyName', value: companyNameNode },
          { key: 'distance', value: distanceNode },
          { key: 'status', value: statusNode },
          { key: 'linkedInUrlNode', value: linkedInUrlNode },
          { key: 'emailAddressNode', value: emailAddressNode },
          { key: 'send', value: sendNode },
          { key: 'delete', value: deleteNode },
        ],
        selected: isSelected,
        isSelectorDisabled: !isAvailable,
        selectRow,
      }
    });

  if (rows === null || rows === undefined || rows.length === 0) {
    return null;
  }

  // Display Text Modal for editing recipient fields.
  const modalOpen = selectedMessageId !== null;
  const closeConfirmationModal = () => setSelectedMessageId(null);

  // Send Recover Client Message Modal
  const sendRecoverClientMessageModalOpen = modalOpen && modalAction === Send;
  const confirmSendRecoverClientMessage = () => {
    closeConfirmationModal();
  };

  // Delete Recipient Modal
  const deleteRecoverClientMessageModalOpen = modalOpen && modalAction === Delete;
  const confirmDeleteRecoverClientMessage = () => {
    closeConfirmationModal();
  };

  const totalRows = rows ? rows.length : 0;
  const maxRowsToDisplay = isTablet ? RECIPIENTS_SMALL_SCREEN_MAX_ROWS : RECIPIENTS_MAX_ROWS;
  return (
    <>
      { sendRecoverClientMessageModalOpen && <ConfirmMessageSendModal cancel={closeConfirmationModal} confirm={confirmSendRecoverClientMessage} /> }
      { deleteRecoverClientMessageModalOpen && <DeleteMessageModal cancel={closeConfirmationModal} confirm={confirmDeleteRecoverClientMessage} /> }
      <section className={'commercial-outreach-table-container'}>
        <section className={'table-container'}>
          <Table
            additionalClassNames={['commercial-outreach-table']}
            headers={headers}
            rows={rows}
            cursor={cursor}
            maxRows={maxRowsToDisplay}
            selectAllRows={selectAllRows}
            hasSelector={hasMessagesToSend}
            hasCounter={true}
          />
        </section>
        <section className={'pagination-bar-container'}>
          <PaginationBar
            cursor={cursor}
            setCursor={setCursor}
            maxRowsPerPage={maxRowsToDisplay}
            totalRows={totalRows}
          />
        </section>
      </section>
    </>
  )
}

export default CommercialOutreachTable;
