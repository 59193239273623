import { Address, AddressType, LetterStatus } from "../case-studies/living-nearby/HandwrittenLettersConstants";
import { OptionType } from "../common/dropdowns/DropdownConstants";
import { Interaction, InteractionType } from "../../types/InteractionTypes";

export const GLOBAL_DATE_FORMATS = [
  'DD/MM/YYYY',
  'DD/M/YYYY',
  'D/MM/YYYY',
  'D/M/YYYY',
  'DD-MM-YYYY',
  'DD-M-YYYY',
  'D-MM-YYYY',
  'D-M-YYYY',
  'DD-MM-YY',
  'DD-M-YY',
  'D-MM-YY',
  'D-M-YY',
  'YYYY/MM/DD',
  'YYYY/MM/D',
  'YYYY/M/DD',
  'YYYY/M/D',
  'YYYY-MM-DD',
  'YYYY-MM-D',
  'YYYY-M-DD',
  'YYYY-M-D',
];
// TODO: Need a flag on businesses for localisation.
export const US_DATE_FORMATS = ['MM/DD/YYYY', 'MM-DD-YYYY', 'YYYY/DD/MM', 'YYYY-DD-MM'];

export enum ModalAction {
  Send = 'Send',
  Create = 'Create',
  Delete = 'Delete',
  Edit = 'Edit'
}

export enum PersonPageToggle {
  Messages = 'Messages',
  Interactions = 'Interactions'
}

export const PersonPageToggleOptions = Object.values(PersonPageToggle);

export const PREFERENCES = {
  SMS: {
    key: 'SMS',
    text: 'SMS'
  },
  EMAIL: {
    key: 'EMAIL',
    text: 'Email'
  }
}

export enum Deliverability {
  SUBSCRIBED = 'SUBSCRIBED',
  OPTED_OUT = 'OPTED_OUT',
};

const { SUBSCRIBED, OPTED_OUT } = Deliverability;

export const DeliverabilityOptions = [
  { displayText: 'Subscribed', value: SUBSCRIBED },
  { displayText: 'Opted out', value: OPTED_OUT },
];

export const DELIVERABILITY : Record<Deliverability, OptionType<Deliverability>> = {
  SUBSCRIBED: {
    value: SUBSCRIBED,
    displayText: 'Subscribed'
  },
  OPTED_OUT: {
    value: OPTED_OUT,
    displayText: 'Opted out'
  },
}

export enum DeliverabilityPreferences {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
};

export const DeliverabilityPreferenceOptions = [
  { displayText: 'SMS', value: DeliverabilityPreferences.SMS },
  { displayText: 'Email', value: DeliverabilityPreferences.EMAIL },
];

export enum Status {
  ACTIVE = 'ACTIVE',
  NOT_ACTIVE = 'NOT_ACTIVE',
  DELETED = 'DELETED'
};

const { ACTIVE, NOT_ACTIVE, DELETED } = Status;

export const StatusDictionary: Record<Status, string> = {
  [ACTIVE]: 'Active',
  [NOT_ACTIVE]: 'Not active',
  [DELETED]: 'Deleted',
};

export enum BooleanPicklist {
  TRUE = 'TRUE',
  FALSE = 'FALSE'
}

export interface Recipient {
  _id: string,
  clientId: string,
  firstName: string,
  lastName: string,
  fullName: string,
  emailAddress: string,
  mobileNumber: string,
  deliverability: Deliverability,
  acceptsSMSMarketing?: BooleanPicklist,
  acceptsEmailMarketing?: BooleanPicklist,
  preferences: DeliverabilityPreferences[],
  addedDate: number | null,
  dateOfBirth: number | null,
  status: Status,
  address: string,
  source: string | null,
  creationDate: number,
  deletionDate: number | null,
  createdBy: string | null,
  businessId: string | null,
  lastInteractionDate?: number,
  lastAppointmentDate?: number,
  lastTransactionDate?: number,
  lastMessageReceivedDate?: number,
  lastMessageSentDate?: number,
  countInteractions?: number,
  countAppointments?: number,
  countTransactions?: number,
  firstTransactionDate?: number,
  lifetimeValue?: number,
};

export const DEFAULT_RECIPIENT = {
  _id: '',
  clientId: '',
  firstName: '',
  lastName: '',
  fullName: '',
  emailAddress: '',
  mobileNumber: '',
  deliverability: SUBSCRIBED,
  preferences: [],
  addedDate: null,
  status: ACTIVE,
  address: '',
  source: null,
  dateOfBirth: null,
  creationDate: Date.now(),
  deletionDate: null,
  createdBy: null,
  businessId: null,
};

export const DEFAULT_ADDRESS = {
  _id: '',
  address: '',
  searchValue: '',
  status: LetterStatus.Requested,
  propertyValue: '',
  addressType: AddressType.Sale,
  transactionDate: '',
  shouldSend: false
}

export interface RecipientId { 
  id: string
}

interface RecipientBatchResponse {
  success: boolean,
  recipientIds: RecipientId[]
}

export interface RecipientBulkImportResponse {
  [ i: string ]: RecipientBatchResponse
}

interface InteractionSubmitBatchResponse {
  success: boolean,
  savedInteractions: FlatFileRecordId[]
};

export interface InteractionBulkSubmitResponse {
  [ i: string ]: InteractionSubmitBatchResponse
}

interface AddressSubmitBatchResponse {
  success: boolean,
  addresses: Address[]
};

export interface AddressBulkSubmitResponse {
  [ i: string ]: AddressSubmitBatchResponse
}

export enum InteractionTypeText {
  TRANSACTION = 'Transaction',
  APPOINTMENT = 'Appointment',
  MESSAGE = 'Message',
};

export const InteractionOptions = [
  { displayText: 'Transaction', value: InteractionType.TRANSACTION },
  { displayText: 'Appointment', value: InteractionType.APPOINTMENT },
  { displayText: 'Message', value: InteractionType.MESSAGE },
]

export const DEFAULT_INTERACTION_SYNC = {
  id: '',
  firstName: '',
  lastName: '',
  fullName: '',
  emailAddress: '',
  mobileNumber: '',
  referenceId: '',
  recipientId: '',
  clientId: '',
};

export interface FlatFileRecordId {
  id: string,
}

export const DEFAULT_INTERACTION: Interaction = {
  id: '',
  firstName: '',
  lastName: '',
  emailAddress: '',
  mobileNumber: '',
  referenceId: '',
  interactionType: InteractionType.APPOINTMENT,
  fullName: '',
  interactionDetails: '',
  scheduledDate: Date.now(),
  transactionCurrency: null,
  transactionValue: 0,
  recipientId: '',
  createdByName: '',
  done: false,
  creationDate: Date.now(),
};

interface PersonDetailFields {
  label: string,
  key: string
}

export const PERSON_DETAIL_FIELDS : PersonDetailFields[] = [
  { label: 'First name', key: 'firstName' },
  { label: 'Last name', key: 'lastName' },
  { label: 'Email', key: 'emailAddress' },
  { label: 'Contact number', key: 'mobileNumber' },
]