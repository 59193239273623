import './MessagePreviewFooter.scss';

import PrimaryButton from '../../../common/buttons/PrimaryButton';
import SubtleButton from '../../../common/buttons/SubtleButton';
import OutlineButton from '../../../common/buttons/OutlineButton';

interface PreviewFooterProps {
  disabled: boolean,
  editing: boolean,
  setEditing: (editing: boolean) => void,
  openTuningModal: () => void,
  save: () => void,
}

function MessagePreviewFooter({ disabled, editing, setEditing, openTuningModal, save } : Readonly<PreviewFooterProps>) {
  const saveChanges = () => {
    save();
    setEditing(false);
  }
  const edit = () => {
    if (!disabled) {
      setEditing(true)
    }
  };
  const editingButton = editing
    ? <PrimaryButton content={'Save'} onClick={saveChanges} />
    : <SubtleButton content={'Edit'} onClick={edit} disabled={disabled} />;
  
  return (
    <footer className={'preview-footer'}>
      {editingButton}
      <OutlineButton content={'Fine tune'} onClick={openTuningModal} disabled={disabled} />
    </footer>
  );
}

export default MessagePreviewFooter;