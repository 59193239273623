import { postData } from './Api';

import { Message } from '../components/case-studies/reactivate-clients/MessageConstants';
import { SuccessResponse } from './types/ResponseTypes';

const PostMessageUrls = {
  FineTuneMessagesUrl: '/api/message/fineTune',
  UpdateMessageUrl: '/api/message/update',
  QueueMessageForFineTuningUrl: '/api/message/fine-tune/queue',
}

const {
  FineTuneMessagesUrl,
  UpdateMessageUrl,
  QueueMessageForFineTuningUrl,
} = PostMessageUrls;

// Request interfaces

/**
 * Request for a list of messages to be fine tuned with a specific prompt and business for context.
 */
interface FineTuneMessagesRequest {
  fineTunePrompt: string,
  messages: Message[],
  businessId: string
}

/** Request for updating a list of messages to the backend. */
interface UpdateMessageRequest {
  messages: Message[],
  businessId: string
}

// Response interfaces

/** List of messages that have been fine tuned. */
interface FineTuneMessagesResponse extends SuccessResponse {
  messages: Message[]
}

/**
 * Fine tunes messages with a specific prompt for a business.
 * TODO: Deprecate this function
 */
export async function postFineTuneMessagesWithPrompt(
  fineTunePrompt: string,
  messages: Message[],
  businessId: string
): Promise<FineTuneMessagesResponse> {
  return postData<FineTuneMessagesRequest, FineTuneMessagesResponse>(
    FineTuneMessagesUrl, { fineTunePrompt, messages, businessId }
  );
}

/**
 * Updates messages for a business.
 */
export async function postQueueMessageToFineTune(
  fineTunePrompt: string,
  messages: Message[],
  businessId: string
): Promise<SuccessResponse> {
  return postData<FineTuneMessagesRequest, SuccessResponse>(
    QueueMessageForFineTuningUrl, { fineTunePrompt, messages, businessId }
  );
}

/**
 * Updates messages for a business.
 */
export async function postUpdateMessages(businessId: string, messages: Message[]): Promise<SuccessResponse> {
  return postData<UpdateMessageRequest, SuccessResponse>(
    UpdateMessageUrl, { messages, businessId }
  );
}