import './RecoverClientsTable.scss';

import { useState } from 'react';
import { useNavigate } from 'react-router';

import useAuth from '../../../../context/AuthContext';

import { ModalAction } from '../../../people/PeopleConstants';
import { brToNewLine } from '../../../../utils/StringHelpers';
import { useMediaQuery } from '../../../../hooks/MediaHooks';
import { postRecoverClientsDeleteMany, postRecoverClientsSendMany } from '../../../../apis/RecoverClientsApi';

import PaginationBar from '../../../common/tables/PaginationBar';
import DeleteMessageModal from '../modals/DeleteMessageModal';
import TrashIconButton from '../../../common/buttons/composite/TrashIconButton';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import Table, { TableHeaderProps } from '../../../common/tables/Table';
import GreyTag from '../../../common/tags/GreyTag';
import GreenTag from '../../../common/tags/GreenTag';
import OutlineButton from '../../../common/buttons/OutlineButton';

import { EnrichedRecoverClientMessage } from '../RecoverClientsConstants';
import { MessageStatus } from '../MessageConstants';

import ConfirmMessageSendModal from '../modals/SendMessageModal';

const LOST_CLIENTS_TABLE_HEADERS: TableHeaderProps[] = [
  { displayText: 'Recipient name', key: 'recipientName' },
  { displayText: 'Send to', key: 'sendTo' },
  { displayText: 'Message status', key: 'messageStatus' },
  { displayText: 'Trigger', key: 'trigger' },
  { displayText: 'Trigger date', key: 'triggerDate' },
  { displayText: 'Our generated message', key: 'message' },
  { displayText: 'View', key: 'view' },
  { displayText: 'Send', key: 'send' },
  { displayText: 'Delete', key: 'delete' },
];

const { Delivered, NotSent } = MessageStatus;

const RECIPIENTS_MAX_ROWS = 5;
const RECIPIENTS_SMALL_SCREEN_MAX_ROWS = 3;

const getStatusNode = (status: MessageStatus) => {
  switch (status) {
    case Delivered:
      return <GreenTag content={status} additionalClassNames={['tag-cell']} />;
    case NotSent:
      return <GreyTag content={status} additionalClassNames={['tag-cell']} />;
    default:
      return null;
  }
};

interface RecoverClientsTableProps {
  selectedRows: EnrichedRecoverClientMessage[],
  setSelectedRows: React.Dispatch<React.SetStateAction<EnrichedRecoverClientMessage[]>>,
  recoverClientMessages: EnrichedRecoverClientMessage[],
  setRecoverClientMessages: React.Dispatch<React.SetStateAction<EnrichedRecoverClientMessage[]>>,
  cursor: number,
  setCursor: React.Dispatch<React.SetStateAction<number>>,
  hasMessagesToSend: boolean,
}

const { Delete, Send } = ModalAction;

function RecoverClientsTable({ selectedRows, setSelectedRows, recoverClientMessages = [], setRecoverClientMessages, cursor, setCursor, hasMessagesToSend } : RecoverClientsTableProps) {
  const navigate = useNavigate();
  const { selectedBusinessId } = useAuth();
  const isTablet = useMediaQuery('(max-height: 720px)');
  const [selectedMessageId, setSelectedMessageId] = useState<string | null>(null);
  const [modalAction, setModalAction] = useState<ModalAction | null>(null);
  const selectAllRows = () => {
    if (selectedRows.length === recoverClientMessages.length) {
      setSelectedRows([]);
    }
    else {
      setSelectedRows(recoverClientMessages);
    }
  };
  const headers = [
    ...LOST_CLIENTS_TABLE_HEADERS
  ]

  const rows = recoverClientMessages
    .map((recoverClientMessage) => {
      const { _id, recipientName, sendTo, trigger, queuedForFineTuning, triggerDate, message, messageStatus } = recoverClientMessage;
      
      const isSelected = !!selectedRows.find((row) => row._id === _id);
      const selectRow = () => {
        if (isSelected) {
          const filteredRows = selectedRows.filter((row) => row._id !== _id);
          setSelectedRows(filteredRows);
        }
        else {
          setSelectedRows([ recoverClientMessage, ...selectedRows ]);
        }
      }
      const recipientNameNode = <section className={'two-cell short-text'} >{recipientName.trim()}</section>
      const mobileNumberNode = <section className={'two-cell short-text'} >{sendTo}</section>
      const messageStatusNode = <section className={'one-button-cell short-text'} >{getStatusNode(messageStatus)}</section>
      const triggerNode = (
        <section className={'long-cell'} >
          <GreyTag content={trigger} additionalClassNames={['long-tag-cell invert']} />
        </section>
      )

      const formattedAddedDate = triggerDate === null ? 'None' : new Date(triggerDate).toLocaleDateString('en-au', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
      const triggerDateNode = <section className={'two-cell short-text'} >{formattedAddedDate}</section>

      const messageNode = <section className={'two-cell long-text'} >{brToNewLine(message)}</section>
      const openModal = (modalAction: ModalAction) => {
        setSelectedMessageId(_id);
        setModalAction(modalAction);
      }

      const viewMessage = () => {
        navigate('/message/edit', { state: { messages: [recoverClientMessage] }});
      };
      const isDeliveredOrTuning = queuedForFineTuning || messageStatus === Delivered;
      const viewTuningText = queuedForFineTuning ? 'Tuning' : 'View';
      const viewNode = <section className={'one-button-cell'}><OutlineButton onClick={() => viewMessage()} disabled={queuedForFineTuning} content={viewTuningText} additionalClassNames={['tag-cell']} /></section>;
      const sendNode = <section className={'one-button-cell'}><PrimaryButton onClick={() => openModal(Send)} content={'Send'} disabled={isDeliveredOrTuning} additionalClassNames={['tag-cell']} /></section>;
      const deleteNode = <TrashIconButton onClick={() => openModal(Delete)} disabled={isDeliveredOrTuning} additionalClassNames={['one-cell']} />;
      return {
        cells: [
          { key: 'recipientName', value: recipientNameNode },
          { key: 'sendTo', value: mobileNumberNode },
          { key: 'messageStatus', value: messageStatusNode },
          { key: 'trigger', value: triggerNode },
          { key: 'triggerDate', value: triggerDateNode },
          { key: 'message', value: messageNode },
          { key: 'view', value: viewNode },
          { key: 'send', value: sendNode },
          { key: 'delete', value: deleteNode },
        ],
        selected: isSelected,
        isSelectorDisabled: isDeliveredOrTuning,
        selectRow,
      }
    });

  if (rows === null || rows === undefined || rows.length === 0) {
    return null;
  }

  // Display Text Modal for editing recipient fields.
  const modalOpen = selectedMessageId !== null;
  const closeConfirmationModal = () => setSelectedMessageId(null);

  // Send Recover Client Message Modal
  const sendRecoverClientMessageModalOpen = modalOpen && modalAction === Send;
  const confirmSendRecoverClientMessage = () => {
    if (selectedMessageId !== null && selectedBusinessId) {
      postRecoverClientsSendMany([selectedMessageId], selectedBusinessId)
        .then(response => {
          console.log(response);
          const { success } = response;
          if (success) {
            const updatedRecoverClientMessages = recoverClientMessages.map((recoverClientMessage) =>
              recoverClientMessage._id === selectedMessageId
                ? ({ ...recoverClientMessage, messageStatus: Delivered })
                : recoverClientMessage
            );
            setRecoverClientMessages(updatedRecoverClientMessages);
          }
        })
    }
    closeConfirmationModal();
  };

  // Delete Recipient Modal
  const deleteRecoverClientMessageModalOpen = modalOpen && modalAction === Delete;
  const confirmDeleteRecoverClientMessage = () => {
    if (selectedMessageId !== null) {
      postRecoverClientsDeleteMany([selectedMessageId])
        .then(response => {
          console.log(response);
          const { success } = response;
          if (success) {
            const filteredRecoverClientMessages = recoverClientMessages.filter(({ _id }) => _id !== selectedMessageId);
            setRecoverClientMessages(filteredRecoverClientMessages);
          }
        })
    }
    closeConfirmationModal();
  };

  const totalRows = rows ? rows.length : 0;
  const maxRowsToDisplay = isTablet ? RECIPIENTS_SMALL_SCREEN_MAX_ROWS : RECIPIENTS_MAX_ROWS;
  return (
    <>
      { sendRecoverClientMessageModalOpen && <ConfirmMessageSendModal cancel={closeConfirmationModal} confirm={confirmSendRecoverClientMessage} /> }
      { deleteRecoverClientMessageModalOpen && <DeleteMessageModal cancel={closeConfirmationModal} confirm={confirmDeleteRecoverClientMessage} /> }
      <section className={'recover-clients-table-container'}>
        <section className={'table-container'}>
          <Table
            additionalClassNames={['recover-clients-table']}
            headers={headers}
            rows={rows}
            cursor={cursor}
            maxRows={maxRowsToDisplay}
            selectAllRows={selectAllRows}
            hasSelector={hasMessagesToSend}
            hasCounter={true}
          />
        </section>
        <section className={'pagination-bar-container'}>
          <PaginationBar
            cursor={cursor}
            setCursor={setCursor}
            maxRowsPerPage={maxRowsToDisplay}
            totalRows={totalRows}
          />
        </section>
      </section>
    </>
  )
}

export default RecoverClientsTable;
