import './ReactivationConditions.scss';

// TODO: Refactor this file

import { ReactivateLostClientConditions, ReactivateLostClientsConditionAttributeKey, DateConditionKey } from '../../../../types/UserTypes';
import ReactivateClientConditionRow from '../../../common/multi-select-rows/filters/ReactivateClientConditionRow';
import PrimaryPlusButton from '../../../common/buttons/composite/PrimaryPlusButton';
import { EMPTY_FILTER } from '../../../common/multi-select-rows/filters/FilterConstants';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import { useState } from 'react';
import { SuccessResponse } from '../../../../apis/types/ResponseTypes';

interface ReactivationConditionsProps {
  reactivationConditions: ReactivateLostClientConditions[],
  updateReactivationConditions: (reactivationConditions: ReactivateLostClientConditions[]) => Promise<SuccessResponse>,
  setReactivationConditions: React.Dispatch<React.SetStateAction<ReactivateLostClientConditions[] | null>>
}

function ReactivationConditions({ updateReactivationConditions, reactivationConditions, setReactivationConditions }: ReactivationConditionsProps) {
  const [loading, setLoading] = useState(false);
  const addFilter = () => {
    const newReactivationConditions = [...reactivationConditions, { ...EMPTY_FILTER }];
    setReactivationConditions(newReactivationConditions);
  }

  const setConditions = (index: number, fieldName: string, value: string | number) => {
    const newFilters = [...reactivationConditions];
    newFilters[index] = { ...newFilters[index], [fieldName]: value };
    setReactivationConditions(newFilters);
  }

  const deleteCondition = (index: number) => {
    const updatedConditions = reactivationConditions.filter((_, i) => i !== index);
    setReactivationConditions(updatedConditions);
  }

  const reactivationConditionsListNode = reactivationConditions.map(({ attribute, condition, input }, index) => {
    const setAttribute = (attribute: ReactivateLostClientsConditionAttributeKey) => setConditions(index, 'attribute', attribute);
    const setCondition = (condition: DateConditionKey) => setConditions(index, 'condition', condition);
    const setInput = (input: number | string) =>  setConditions(index, 'input', input);
    return (
      <ReactivateClientConditionRow
        key={`filter-${index}`}
        attribute={attribute}
        setAttribute={setAttribute}
        condition={condition}
        setCondition={setCondition}
        input={input}
        setInput={setInput}
        deleteRow={() => deleteCondition(index)}
      />
    );
  });

  const updateConditions = async () => {
    setLoading(true);
    await updateReactivationConditions(reactivationConditions);
    setLoading(false);
  }

  const hasReactivationConditions = reactivationConditionsListNode.length > 0;
  return (
    <section className={'reactivation-conditions'}>
      <section className={'section-container'}>
        <h4 className={'input-title'}>{'Define when a client should be re-activated'}</h4>
        <h5 className={'input-subtitle'}>{'You can add multiple filters'}</h5>
      </section>
      {hasReactivationConditions && (
        <section className={'section-container'}>
        <section className={'reactivate-condition-list'}>
          {reactivationConditionsListNode}
        </section>
      </section>
      )}
      <section className={'section-container'}>
        <section className={'button-footer'}>
          <PrimaryPlusButton content={'New filter'} onClick={addFilter} />
          <PrimaryButton content={'Save'} loading={loading} onClick={updateConditions} />
        </section>
      </section>
    </section>
  )
}

export default ReactivationConditions;