import './ProgressLevels.scss';

import { ReactElement } from 'react';

import FILLED_LEVEL from './assets/filled-progress-level.svg';
import UNFILLED_LEVEL from './assets/unfilled-progress-level.svg';

interface ProgressLevelsProps {
  filled: number,
  total: number,
}

function ProgressLevels({ filled, total } : Readonly<ProgressLevelsProps>): ReactElement<ProgressLevelsProps> {
  const levels = Array(total).fill(null).map((_, index) => {
    if (index + 1 <= filled) {
      return <img key={`filled-${index}`} className={'filled-level'} src={FILLED_LEVEL} alt={'filled level'} />;
    }
    return <img key={`unfilled-${index}`} className={'unfilled-level'} src={UNFILLED_LEVEL} alt={'unfilled level'} />;
  });
  return (
    <section className={'progress-levels'}>
      {levels}
    </section>
  )
}

export default ProgressLevels;