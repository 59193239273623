import './CommercialOutreachFilterBar.scss';

import { ReactElement } from 'react';

import SearchInput from '../../../common/inputs/SearchInput';
import QuickSelectButton from '../../../common/buttons/quick-select/QuickSelectButton';
import Filter from '../../../common/filters/Filter';
import { LinkedInUser, LinkedInUserFilterAttributeMap, LinkedInUserFilterAttributeOptions } from '../CommercialOutreachConstants';
import { FilterItem } from '../../../common/filters/types/FilterTypes';


interface CommercialOutreachFilterBarProps {
  searchForLinkedInUsers: (searchText: string) => void,
  text: string,
  linkedInUsers: LinkedInUser[],
  linkedInUsersToDisplay: LinkedInUser[],
  filters: FilterItem[],
  setFilters: React.Dispatch<React.SetStateAction<FilterItem[]>>,
  quickSelectRows: (amount: number) => void
};

function CommercialOutreachFilterBar({
  searchForLinkedInUsers,
  text,
  linkedInUsers,
  linkedInUsersToDisplay,
  filters,
  setFilters,
  quickSelectRows
} : Readonly<CommercialOutreachFilterBarProps>): ReactElement {

  return (
    <section className={'search-and-filter-bar'}>
      <SearchInput
        placeholder={'Type to search'}
        text={text}
        setText={searchForLinkedInUsers}
      />
      <Filter
        rows={linkedInUsers}
        filteredRows={linkedInUsersToDisplay}
        filters={filters}
        setFilters={setFilters}
        searchText={text}
        filterAttributeMap={LinkedInUserFilterAttributeMap}
        filterOptions={LinkedInUserFilterAttributeOptions}
      />
      <QuickSelectButton quickSelectRows={quickSelectRows} />
    </section>
  )
}

export default CommercialOutreachFilterBar;