import { useEffect, useState } from 'react';

import { BusinessEvent } from '../../../../types/BusinessTypes';
import { getBusinessEvents } from '../../../../apis/BusinessApi';

interface BusinessMetricsData {
  events: BusinessEvent[],
  setEvents: (businessEvents: BusinessEvent[]) => void,
  loading: boolean,
};

export function useBusinessEvents(businessId: string | null): BusinessMetricsData {
  const [events, setEvents] = useState<BusinessEvent[] | null>(null);

  useEffect(() => {
    if (businessId) {
      setEvents(null);
      getBusinessEvents(businessId).then(({ businessEvents }) => setEvents(businessEvents));
    }
  }, [businessId]);
  
  return {
    events: events || [],
    setEvents,
    loading: events === null,
  };
}