import { useEffect, useState, Dispatch, SetStateAction } from 'react';

import { CommercialOutreachStatus, LinkedInUser } from '../CommercialOutreachConstants';

interface BusinessLinkedInUsersData {
  linkedInUsers: LinkedInUser[],
  setLinkedInUsers: (linkedInUsers: LinkedInUser[]) => void,
  loading: boolean,
  cursor: number,
  setCursor: Dispatch<SetStateAction<number>>,
};

const DUMMY_COMMERCIAL_OUTREACH_DATA = [
  {
    _id: '1',
    fullName: 'John Doe',
    companyName: 'Company name LTD',
    addressLocation: {
      latitude: -37.81463963564024,
      longitude: 144.9621565682168,
    },
    status: CommercialOutreachStatus.Available,
    linkedInUrl: 'https://www.linkedin.com/in/andrewzhang9799/',
    emailAddress: 'johndoe@email.com',
  },
  {
    _id: '2',
    fullName: 'William Wonka',
    companyName: 'Choco Factory LTD',
    addressLocation: {
      latitude: -37.81463963564024,
      longitude: 144.9621565682168,
    },
    status: CommercialOutreachStatus['In progress'],
    linkedInUrl: 'https://www.linkedin.com/in/andrewzhang9799/',
    emailAddress: 'willWonk@email.com',
  },
  {
    _id: '3',
    fullName: 'Germaine Tin',
    companyName: 'Tank Tone LTD',
    addressLocation: {
      latitude: -37.81463963564024,
      longitude: 144.9621565682168,
    },
    status: CommercialOutreachStatus.Completed,
    linkedInUrl: 'https://www.linkedin.com/in/andrewzhang9799/',
    emailAddress: 'Germatin@email.com',
  },
]

export function useBusinessLinkedInUsers(businessId: string | null): BusinessLinkedInUsersData {
  const [cursor, setCursor] = useState(0);
  const [linkedInUsers, setLinkedInUsers] = useState<LinkedInUser[]| null>(null);
  
  useEffect(() => {
    if (businessId) {
      setCursor(0);
      setLinkedInUsers(null);
      setLinkedInUsers(DUMMY_COMMERCIAL_OUTREACH_DATA);
    }
  }, [businessId]);

  return {
    linkedInUsers: linkedInUsers || [],
    setLinkedInUsers,
    loading: linkedInUsers === null,
    cursor,
    setCursor,
  };
}