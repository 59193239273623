import { Currency } from './GeoTypes';

export enum InteractionType {
  TRANSACTION = 'TRANSACTION',
  APPOINTMENT = 'APPOINTMENT',
  MESSAGE = 'MESSAGE',
};

export interface InteractionSyncFields {
  id: string,
  firstName: string,
  lastName: string,
  fullName: string,
  emailAddress: string,
  mobileNumber: string,
  referenceId: string,
  recipientId: string,
  clientId: string,
};

export interface Interaction {
  id: string,
  firstName: string,
  lastName: string,
  emailAddress: string,
  mobileNumber: string,
  referenceId: string,
  interactionType: InteractionType,
  fullName: string,
  interactionDetails: string,
  scheduledDate: number,
  transactionCurrency: Currency | null,
  transactionValue: number,
  recipientId: string,
  creationDate: number,
  createdByName: string,
  done: boolean,
};