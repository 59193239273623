import { getData, postData } from './Api';

import { SuccessResponse } from './types/ResponseTypes';
import { Lead, LeadNurtureFlow } from '../types/LeadTypes';

const GetLeadUrls = {
  GetAllLeadsUrl: '/api/lead/all/'
};

const PostLeadUrls = {
  DeleteLeadUrl: '/api/lead/delete',
  BeginLeadNurtureFlowUrl: '/api/lead/nurture/begin',
  StopLeadNurtureFlowUrl: '/api/lead/nurture/stop',
}

const { GetAllLeadsUrl } = GetLeadUrls;
const { DeleteLeadUrl, BeginLeadNurtureFlowUrl, StopLeadNurtureFlowUrl } = PostLeadUrls;

// Request interfaces

/** Request for deleting a lead. Passes back the lead id to the backend for deletion. */
interface LeadRequest {
  leadId: string,
}

// Response interfaces

/** Response from getting all leads for a particular business. */
interface GetAllLeadsResponse extends SuccessResponse {
  leads: Lead[],
}

/** Response from updating a nurture flow for a particular lead. */
interface NurtureFlowResponse extends SuccessResponse {
  nurtureFlow: LeadNurtureFlow,
}

// GET requests

/**
 * Fetch all leads associated with a business.
 */
export async function getBusinessLeads(businessId: string): Promise<GetAllLeadsResponse> {
  return getData<GetAllLeadsResponse>(`${GetAllLeadsUrl}${businessId}`);
}

// POST requests

/**
 * Post a lead that should stop a nurture flow.
 */
export async function postStopNurtureFlow(leadId: string): Promise<NurtureFlowResponse> {
  const { success, nurtureFlow } = await postData<LeadRequest, NurtureFlowResponse>(StopLeadNurtureFlowUrl, { leadId });
  return { success, nurtureFlow };
};

/**
 * Post a lead that should begin a nurture flow.
 */
export async function postBeginNurtureFlow(leadId: string): Promise<NurtureFlowResponse> {
  const { success, nurtureFlow } = await postData<LeadRequest, NurtureFlowResponse>(BeginLeadNurtureFlowUrl, { leadId });
  return { success, nurtureFlow };
};

/**
 * Post a lead id for deletion.
 */
export async function postDeleteLead(leadId: string): Promise<SuccessResponse> {
  const { success } = await postData<LeadRequest, SuccessResponse>(DeleteLeadUrl, { leadId });
  return { success };
};