import './CaseStudyCardsContainer.scss';

import { ReactElement } from 'react';

import { CaseStudies } from '../DashboardConstants';

import CaseStudyCard from './CaseStudyCard';

// TODO: Rename CaseStudy to an more appropriate name for LivingNearby, WorkingNearby, ReactivateLostClients
function CaseStudyCardsContainer(): ReactElement {
  const caseStudyCards = CaseStudies
    .map(({ key, title, previewUrl, navigationUrl, descriptions, type }) => 
      <CaseStudyCard key={key} title={title} previewUrl={previewUrl} navigationUrl={navigationUrl} descriptions={descriptions} type={type} />);
  return (
    <section className={'case-study-cards-container'}>
      <header className={'case-study-cards-header'}>
        <h2 className={'title'}>{'Browse our services'}</h2>
        <h3 className={'subtitle'}>{'Click to learn more about each of our services'}</h3>
      </header>
      <section className={'cards'}>
        {caseStudyCards}
      </section>
  </section>
  )
}

export default CaseStudyCardsContainer;