import { FlatfileProvider } from "@flatfile/react";

import { prodFlatFileAuth } from "../../Constants";
import RecipientFlatFileButton from "../../components/people/recipients/common/RecipientFlatFileButton";
import { flatfileConfig, RecipientFlatFileButtonProps } from "./FlatFileConstants";

function RecipientFlatfileImport({ defaultDisplayText } : RecipientFlatFileButtonProps) {
  return (
    <FlatfileProvider
      publishableKey={prodFlatFileAuth.publishableKey}
      config={flatfileConfig}
    >
      <RecipientFlatFileButton defaultDisplayText={defaultDisplayText} />
    </FlatfileProvider>
  )
};

export default RecipientFlatfileImport;