import './ButtonContent.scss';

import { IconButtonContentProps } from '../ButtonConstants';

function LeftIconButtonContent({ icon, content }: Readonly<IconButtonContentProps>) {
  return (
    <section className={'button-content'}>
      <img className={'icon'} src={icon} alt={''} />
      {content}
    </section>
  )
}

export default LeftIconButtonContent;