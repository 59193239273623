import { postData } from './Api';

import { Interaction, InteractionSyncFields } from '../types/InteractionTypes';
import { Recipient } from '../components/people/PeopleConstants';
import { SuccessResponse } from './types/ResponseTypes';

const PostInteractionUrls = {
  SaveOrUpdateInteractionUrl: '/api/interaction/saveOrUpdate',
  SaveInteractionsUrl: '/api/interactions/save',
  SyncInteractionsUrl: '/api/interactions/sync',
};

const {
  SyncInteractionsUrl,
  SaveInteractionsUrl,
  SaveOrUpdateInteractionUrl,
} = PostInteractionUrls;

// Request interfaces

/**
 * Request for when uploading a list of interactions to be synced to their recipient ids on the backend.
 * Sends over a list of a interaction fields that can be used to match to their recipient id.
 */
interface SyncInteractionsRequest {
  interactions: InteractionSyncFields[],
  businessId: string,
};

/**
 * Request for saving interactions for a particular business.
 */
interface SaveInteractionsRequest {
  interactions: Interaction[],
  businessId: string,
};

/**
 * Request for updating a list of interactions for a particular business.
 */
interface SaveUpdateInteractionsRequest {
  interaction: Interaction
};

// Response interfaces

/**
 * Response from uploading a list of interactions to be synced with their recipient ids in the backend.
 * Interactions should contain the corresponding recipient id if any.
 */
interface SyncInteractionsResponse extends SuccessResponse {
  syncedInteractions: InteractionSyncFields[]
};

/**
 * Response from saving a list of interactions.
 */
interface SaveInteractionsResponse extends SuccessResponse {
  recordIds: string[]
};

/**
 * Response for saving an individual interaction. 
 * Returns the recipient that was associated with the interaction enriched with interaction data.
 */
interface SaveUpdateInteractionResponse extends SuccessResponse {
  recipient: Recipient
};

/**
 * Posts a list of interactions to the backend and finds the corresponding recipient associated with each interaction if any.
 * Returns a list of interactions that were successfully synced with recipient ids.
 */
export async function postSyncInteractions(interactions: InteractionSyncFields[], businessId: string): Promise<SyncInteractionsResponse> {
  const { success, syncedInteractions } = await postData<SyncInteractionsRequest, SyncInteractionsResponse>(
    SyncInteractionsUrl, { interactions, businessId }
  );
  return { success, syncedInteractions };
};

/**
 * Saves a list of interactions. Returns a promise with the list of interactionIds that got updated.
 */
export async function postSaveInteractions(interactions: Interaction[], businessId: string): Promise<SaveInteractionsResponse> {
  const { success, recordIds } = await postData<SaveInteractionsRequest, SaveInteractionsResponse>(
    SaveInteractionsUrl, { interactions, businessId }
  );
  return { success, recordIds };
};

/**
 * Saves or updates an interaction. Returns a promise with the recipient enriched with the latest interaction data.
 */
export async function postSaveOrUpdateInteraction(interaction: Interaction): Promise<SaveUpdateInteractionResponse> {
  const { success, recipient } = await postData<SaveUpdateInteractionsRequest, SaveUpdateInteractionResponse>(
    SaveOrUpdateInteractionUrl, { interaction }
  );
  return { success, recipient };
};