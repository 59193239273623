import { CountryCode, Location } from './GeoTypes';
import { EmailForwardingSettings } from './UserTypes';
import { LeadSource } from './LeadTypes';
import { ReactivateLostClientConditions } from './UserTypes';

export interface NurtureStep {
  daysToWait: number,
  message: string,
}

export interface NurtureFlowSettings {
  stopNurtureIfResponse: boolean,
  steps: NurtureStep[],
}

export interface LeadNurtureSettings {
  nurtureFlows: {
    [source in LeadSource]: NurtureFlowSettings
  }
}

export enum ActivityType {
  HandwrittenLetter = 'Handwritten letter',
  Reactivation = 'Re-activation',
  FollowUp = 'Follow up',
}

export interface BusinessEvent {
  businessId: string,
  eventType: ActivityType,
  eventDescription: string,
  contextId: string,
  creationDate: number,
}

export interface Business {
  _id: string,
  businessName: string,
  businessAddress: string,
  businessType: string,
  businessLocation: Location,
  countryCode: CountryCode,
  offerUrl: string | null | undefined,
  letterUrl: string | null | undefined,
  programUrl: string | null | undefined,
  messageUrl: string | null | undefined,
  clientToLeadsProportion: number | undefined,
  revenuePerClient: number | undefined,
  emailForwardingSettings: EmailForwardingSettings,
  leadNurtureSettings: LeadNurtureSettings,
  reactivateLostClientConditions: ReactivateLostClientConditions[],
}