import './RecoverClientsSetupPage.scss';

import { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router';

import { postRecoverClientsSetup } from '../../../apis/RecoverClientsApi';
import useAuth from '../../../context/AuthContext';

import PrimaryButton from '../../common/buttons/PrimaryButton';
import TextAreaInput from '../../common/inputs/TextAreaInput';

function RecoverClientsSetupPage(): ReactElement {
  const { selectedBusinessId } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [requestDetails, setRequestDetails] = useState('');
  const createRequest = async () => {
    if (selectedBusinessId) {
      setLoading(true);
      await postRecoverClientsSetup(selectedBusinessId, requestDetails);
      setLoading(false);
    }
    navigate('/recover-clients/manage');
  };

  const disabled = requestDetails.length === 0;
  return (
    <article className={'recover-clients-setup-page'}>
      <section className={'page'}>
        <header className={'recover-clients-setup-page-header'}>
          <h2 className={'title'}>{'Recover lost clients'}</h2>
          <h3 className={'subtitle'}>{'We just need a few details to help us re-engage your lost clients'}</h3>
        </header>
        <section className={'page-content'}>
          <section className={'section-container'}>
            <h5 className={'input-title'}>{'Describe the type of clients you want to re-engage'}</h5>
            <section className={'input-container'}>
              <TextAreaInput
                text={requestDetails}
                setText={setRequestDetails}
                placeholder={'High spending clients that haven\'t been returned in the last 2 years'}
                id={'describe-client-type-input'}
              />
            </section>
          </section>
          <PrimaryButton
            content={'Create'}
            onClick={createRequest}
            additionalClassNames={['create-request-button']}
            disabled={disabled}
            loading={loading}
          />
        </section>
      </section>
    </article>
  );
}

export default RecoverClientsSetupPage;