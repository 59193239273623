import './EmailForwarding.scss';

import { ReactElement, useState } from 'react';

import { EMAIL_FORWARDING_OPTIONS, EmailForwardingPreferences, EmailForwardingSettings } from '../../../types/UserTypes';

import Dropdown from '../../common/dropdowns/Dropdown';
import PrimaryInput from '../../common/inputs/PrimaryInput';
import PrimaryButton from '../../common/buttons/PrimaryButton';

import EmailList from './EmailList';

interface EmailForwardingProps {
  emailForwardingSettings: EmailForwardingSettings,
  setEmailForwardingSettings: (emailForwardingSettings: EmailForwardingSettings) => void
  phoneNumbers: string[],
  subtitle: string,
}

const validateEmail = (email: string): boolean => {
  const emailValidationRegex = /^\S+@\S+\.\S+$/;
  return emailValidationRegex.test(email.toLowerCase());
};

function EmailForwarding({ emailForwardingSettings, setEmailForwardingSettings, phoneNumbers, subtitle }: EmailForwardingProps): ReactElement {
  const [emailAddress, setEmailAddress] = useState('');
  const { emailToSmsPreference, emailForwardingList, mobileNumber } = emailForwardingSettings;
  const phoneNumberOptions = phoneNumbers.map(phoneNumber => ({ displayText: phoneNumber, value: phoneNumber }));
  const setEmailToSmsPreference = (emailToSmsPreference: EmailForwardingPreferences) => setEmailForwardingSettings({ ...emailForwardingSettings, emailToSmsPreference });
  const setMobileNumber = (mobileNumber: string) => setEmailForwardingSettings({ ...emailForwardingSettings, mobileNumber });
  const addEmailAddress = () => {
    if (validateEmail(emailAddress) && !emailForwardingList.includes(emailAddress)) {
      setEmailForwardingSettings({ ...emailForwardingSettings, emailForwardingList: [...emailForwardingList, emailAddress] });
      setEmailAddress('');
    }
  }
  const isValidEmailAddress = validateEmail(emailAddress);
  const removeEmail = (index: number) => setEmailForwardingSettings({
    ...emailForwardingSettings,
    emailForwardingList: emailForwardingList.filter((_, i) => index !== i)
  });
  return (
    <section className={'email-forwarding'}>
      <section className={'settings-header'}>
        <h4 className={'settings-title'}>{'Email-to-SMS forwarding and replies'}</h4>
        <h5 className={'settings-subtitle'}>{subtitle}</h5>
      </section>
      <section className={'preferences'}>
        <section className={'preference'}>
          <span className={'preference-title'}>{'Forwarding preferences'}</span>
          <Dropdown
            options={EMAIL_FORWARDING_OPTIONS}
            selected={emailToSmsPreference}
            select={setEmailToSmsPreference}
            defaultDisplayText={'Forwarding preferences'}
          />
        </section>
        <section className={'preference'}>
          <span className={'preference-title'}>{'SMS sender'}</span>
          <Dropdown
            options={phoneNumberOptions}
            selected={mobileNumber}
            select={setMobileNumber}
            defaultDisplayText={'Select phone number'}
          />
        </section>
        <section className={'preference'}>
          <span className={'preference-title'}>{'Forward replies to email'}</span>
          <span className={'preference-subtitle'}>{'You can add up to 3 recipients'}</span>
          <section className={'email'}>
            <section className={'label'}>{'Email:'}</section>
            <PrimaryInput placeholder={'Type email address'} text={emailAddress} setText={setEmailAddress} />
            <PrimaryButton content={'Add'} onClick={addEmailAddress} disabled={!isValidEmailAddress} />
          </section>
          <EmailList emails={emailForwardingList} removeEmail={removeEmail} />
        </section>
      </section>
    </section>
  )
}

export default EmailForwarding;