import parser from 'any-date-parser';

// TODO: Delete this file after refactoring

import { RecipientFilterType, RECIPIENTS_FILTER_ATTRIBUTES_MAP, FILTER_CONDITIONS_MAP, RECOVER_CLIENTS_FILTER_ATTRIBUTES_MAP, RecoverClientsFilterType } from './FilterConstants';
import { CountryCode } from '../../../../types/GeoTypes';
import { Recipient } from '../../../people/PeopleConstants';
import { EnrichedRecoverClientMessage } from '../../../case-studies/reactivate-clients/RecoverClientsConstants';
import { FilterType } from '../../filters/types/FilterTypes';

export interface RecipientRow extends Recipient {
  _id: string,
  alreadySelected: boolean,
}

const { STRING, NUMBER, DATE } = FilterType;

export function filterRecipients(recipients: RecipientRow[] | null, filters: RecipientFilterType[]) {
  if (recipients) {
    return recipients.filter((recipient: RecipientRow | Recipient) => {
      // Only compute filters with conditions
      const populatedFilters = filters.filter((filter: RecipientFilterType) => filter.condition);
  
      for (const filter of populatedFilters) {
        const { attribute, condition, input } = filter;
        if (!attribute || !condition) {
          return false;
        }

        const recordVal = Object.hasOwn(recipient, attribute)
          ? recipient[attribute]
          : null;
  
        const attributeType = RECIPIENTS_FILTER_ATTRIBUTES_MAP[attribute].type;
        const conditionObject = FILTER_CONDITIONS_MAP[attributeType][condition];
  
        if (!conditionObject) return false;
  
        let filterResult = false;
  
        const comparedValueTypes = [STRING, NUMBER, DATE];
        if (comparedValueTypes.includes(attributeType)) {
          filterResult = conditionObject.filterFunction(recordVal, input);
        }
  
        if (!filterResult) {
          return false;
        }
      }
  
      return true;
    })
  }
  return [];
}

function validateAndFormatNumeric(value: any, valueType: FilterType, countryCode: CountryCode | null) {
  if (typeof value === 'string' && valueType === DATE) {
    const date = countryCode ? parser.fromString(value, countryCode) : parser.fromString(value);
    return typeof date.getTime === 'function' ? date.getTime() : null;
  }
  if (typeof value === 'string' && valueType === NUMBER) {
    return value.replace(/\D/g, '');
  }
  return value;
}

export function searchRecipients(recipients: RecipientRow[], searchText: string) {
  if (searchText.length === 0) {
    return recipients;
  }
  if (recipients) {
    return recipients.filter(({ firstName, lastName }) => {
      const name = `${firstName ? firstName : ''}${lastName ? ` ${lastName}` : ''}`;
      return name.toLowerCase().includes(searchText.toLowerCase());
    })
  }
  return [];
};

export function filterRecoverClientMessages(recoveryMessages: EnrichedRecoverClientMessage[] | null, filters: RecoverClientsFilterType[]) {
  if (recoveryMessages) {
    return recoveryMessages.filter((recoveryMessage: EnrichedRecoverClientMessage) => {
      // Only compute filters with conditions
      const populatedFilters = filters.filter((filter: RecoverClientsFilterType) => filter.condition);
  
      for (const filter of populatedFilters) {
        const { attribute, condition, input } = filter;
        if (!attribute || !condition) {
          return false;
        }

        const attributeType = RECOVER_CLIENTS_FILTER_ATTRIBUTES_MAP[attribute].type;
        const conditionObject = FILTER_CONDITIONS_MAP[attributeType][condition];

        const recordVal = Object.hasOwn(recoveryMessage, attribute)
          ? validateAndFormatNumeric(recoveryMessage[attribute], attributeType, null)
          : null;
  
        if (!conditionObject) return false;
  
        let filterResult = false;
  
        const comparedValueTypes = [STRING, NUMBER, DATE];
        if (comparedValueTypes.includes(attributeType)) {
          filterResult = conditionObject.filterFunction(recordVal, input);
        }
  
        if (!filterResult) {
          return false;
        }
      }
  
      return true;
    })
  }
  return [];
}