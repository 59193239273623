export const CHANNEL_OPTIONS = [
  {
    displayText: 'Email',
    value: 'Email',
  },
  {
    displayText: 'SMS',
    value: 'SMS',
  },
];

export const OFFER_OPTIONS = [
  {
    displayText: 'Offer name 1',
    value: 'Offer name 1',
  },
  {
    displayText: 'Offer name 2',
    value: 'Offer name 2',
  },
  {
    displayText: 'Offer name 3',
    value: 'Offer name 3',
  },
  {
    displayText: 'Offer name 4',
    value: 'Offer name 4',
  },
];

export const FORWARDING_PREFERENCES = [
  {
    displayText: 'None',
    value: 'None',
  },
  {
    displayText: 'All replies',
    value: 'All replies',
  },
  {
    displayText: 'Only replies that may require customer action',
    value: 'Only replies that may require customer action',
  }
];

export const RECIPIENT_SELECT = [
  {
    displayText: 'Randomly assign',
    value: 'Randomly assign',
  },
  {
    displayText: 'Manually assign',
    value: 'Manually assign',
  },
];

export const PRODUCT_OPTIONS = [
  {
    displayText: 'No product',
    value: 'No product',
  },
  {
    displayText: 'Product 1',
    value: 'Product 1',
  },
  {
    displayText: 'Product 2',
    value: 'Product 2',
  },
  {
    displayText: 'Product 3',
    value: 'Product 3',
  },
];

export const prodFlatFileAuth = {
  environmentId: 'us_env_KMEVFY0N',
  publishableKey: 'pk_0ae11e0be7d242c0aa64c80193689b67'
};